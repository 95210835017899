import { Checkbox, message, Radio, Table, Modal, Input, Button, Select, DatePicker } from 'antd';
import React, { Component } from 'react';
import { getDepartment_Data } from '../../config/storage';
import { getTaskList } from '../../services/task';
import { getLabels, postAppeal, delReminder } from '../../services/orderCore';
import { getServiceList, } from '../../services/serviceManagement';
import { jsToExcel } from '../../utils/exportExcel';
import styles from './index.module.css';
import PageTurning from '../../components/pageTurning';
import IndexModal from './indexModal';
import DetailModal from './detailModal';
import _ from 'lodash';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

moment.locale('zh-cn');

const dateFormat = 'YYYY-MM-DD';
const types = ['回访', '投诉', '培训', '评价'];
const today = moment().add(1, 'days').format(dateFormat);
const monthAgo = moment().subtract(1, 'month').format(dateFormat);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelData: [],
      taskbase: {},
      radioType: '待处理',
      serviceType: '全部',
      withMe: true,
      data: [],
      start: monthAgo,
      end: today,
      skip: 0,
      take: 10,
      info: '',
      visible: false,
      title: '我的任务',
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
        orderid: 0,
        worker: 0,
      },
      selectedRowKeys: [],
      serviceList: [],
      backInfo: {
        normal: '',
        clear: '',
        box: '',
        site: '',
        space: '',
        outside: '',
        detail: '',
        round: '',
        sign: '',
        talk: '',
        late: '',
        attitude: '',
        mark: '',
      },
    }
    this.baseUrl = getDepartment_Data().service;
    this.page = React.createRef();
    this.indexModal = React.createRef();
    this.detailModal = React.createRef();
  }
  componentDidMount() {
    this.getServices()
  }
  render() {
    const { selectedRowKeys, serviceType, start, end, radioType, withMe, data, visible, title, info,
      addModal, serviceList, backInfo } = this.state;
    this.columns = [
      {
        title: '订单信息',
        dataIndex: 'infl',
        align: 'center',
        width: 110,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns-span']}`}>{record.service}-{record.order}</div>
              <div className={`${styles['columns-span']} margin-t5`}>{record.category + '-'}<span className='fw600 color4'>{record.type}</span></div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.state} 】</div>
            </div>
          )
        }
      },
      {
        title: '客户信息',
        dataIndex: 'name',
        align: 'center',
        width: 120,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div>{record.name}</div>
              <div>{record.phone}</div>
              <div>{record.province + record.city + record.district + record.address}</div>
            </div>
          );
        }
      },
      {
        title: '商家信息',
        dataIndex: 'shopName',
        align: 'center',
        width: 100,
        className: 'padding0',
      },
      {
        title: '师傅信息',
        dataIndex: 'workerName',
        align: 'center',
        width: 60,
        className: 'padding0',
      },
      {
        title: '处理状态',
        dataIndex: 'state',
        align: 'center',
        width: 60,
        className: 'padding0',
      },
      {
        title: '标记人',
        dataIndex: 'subName',
        align: 'center',
        width: 180,
        className: 'padding0',
        render: (text, record, index) => {
          let exit = serviceList.findIndex(r => r.id === record.submitter);
          return (
            <div>
              <div>{exit < 0 ? '' : serviceList[exit].name}</div>
              <div className={`${styles['columns-span']}`}>{this.removeT(record.submit_time)}</div>
              <div>{record.content}</div>
            </div>
          );
        }
      },
      {
        title: '图片',
        dataIndex: 'pic',
        align: 'center',
        width: 60,
        className: 'padding0',
        render: (text, record, index) => {
          let pic = record.pic ? JSON.parse(record.pic) : [];
          return (
            <div className='col-t'>
              {
                pic.map((item, pindex) => {
                  return <div onClick={this.preview.bind(this, item)}
                    className='color3 underline cursor-pointer' key={pindex}
                  >
                    图片{pindex + 1}
                  </div>
                })
              }
            </div>
          );
        }
      },
      {
        title: '处理人',
        dataIndex: 'chkName',
        align: 'center',
        width: 120,
        className: 'padding0',
        render: (text, record, index) => {
          let result = (moment(record.check_time).unix() - moment(record.submit_time).unix()) / 60 / 60;
          return (
            <div>
              <div>{record.chkName}</div>
              <div className='row'>  {
                record.check_time !== '0001-01-01T00:00:00' ? (
                  <div className={styles['columns-span']}>{this.removeT(record.check_time)}</div>
                ) : (
                  <div className={styles['columns-span']}>--</div>
                )
              }
              </div>
              <div>{record.state === '已处理' ? '时效' + result.toFixed(1) + 'h' : '--'}</div>
            </div>
          );
        }
      },
      // {
      //   title: '处理备注',
      //   dataIndex: 'remark',
      //   align: 'center',
      //   width: 120,
      //   className: 'padding0',
      // },
      {
        title: '操作',
        dataIndex: 'operate',
        align: 'center',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div onClick={this.toDetailRecord.bind(this, record.remark)} className={`${styles['columns2-copy-button']} margin-b10`}>跟进记录</div>
              {record.state === '待处理' &&
                <div className={styles['columns2-copy-button']} onClick={this.onFollowUp.bind(this, record)}>跟进处理</div>}
              <div className={`${styles['columns2-copy-button2']} margin-t10`}
                onClick={this.newRecord.bind(this, record)}
              >
                新增回访</div>
            </div>
          )
        }
      },
    ];
    const rowSelection = {
      // columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'checkbox',
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <Modal
          title={title}
          width={1600}
          visible={visible}
          onCancel={this.onCancel}
          onOk={this.onCancel}
          okText='确定'
          cancelText='取消'
        >
          <div className='w100 padding-b20 back-color0 row-l'>
            <div>时间:</div>
            <DatePicker placeholder='开始时间' value={moment(start, dateFormat)}
              onChange={this.onChangeDate.bind(this, 'start')} locale={locale} allowClear={false} />
            ~
            <DatePicker placeholder='结束时间' value={moment(end, dateFormat)}
              onChange={this.onChangeDate.bind(this, 'end')} locale={locale} allowClear={false} />
            <span className='margin-lr10'>处理状态:</span>
            <Radio.Group onChange={this.onRadio} value={radioType}>
              <Radio value={'待处理'}>待处理</Radio>
              <Radio value={'已处理'}>已处理</Radio>
            </Radio.Group>
            <Checkbox checked={withMe} onChange={this.onCheckbox}>仅看与我有关</Checkbox>
          </div>
          <div className='w100 back-color0 row-sb margin-b10'>
            <div className='row-l'>
              <div className='w150'>服务类型：</div>
              <Select value={serviceType} onChange={this.changeService} className='w120'>
                <Select.Option value='全部'>全部</Select.Option>
                <Select.Option value='测量'>测量</Select.Option>
                <Select.Option value='安装'>安装</Select.Option>
              </Select>
              <Input value={info} onChange={this.changeInfo} />
              <Button type='primary' onClick={this.toSearch}>搜索</Button>
              <div className={styles['reminder_styles']} onClick={this.onReminder}>
                {`批量处理(${selectedRowKeys.length})`}
              </div>
            </div>
            <Button type='primary' onClick={this.toExcel}>导出表格</Button>
          </div>
          <div className=' back-color0 margin-t10'>
            <Table
              rowSelection={rowSelection}
              columns={this.columns}
              dataSource={data}
              size={'small'}
              rowKey={'id'}
              bordered
              pagination={false}
            />
            <PageTurning pageMaps={this.pageMaps} ref={this.page} />
          </div>
          <IndexModal
            ref={this.indexModal}
            updateTable={this.updateTable}
          />
          <DetailModal
            ref={this.detailModal}
          />
          <Modal
            title='新增记录'
            visible={addModal.visible}
            width={600}
            onCancel={this.onCancelAdd}
            footer={
              <div>
                <Button onClick={this.onCancelAdd} type='ghost'>取消</Button>
                <Button onClick={this.addRecord} type='primary'>确认</Button>
              </div>
            }
          >
            <div className='row-l'>
              <div className='w60'>类型：</div>
              <Radio.Group value={addModal.type} onChange={this.changeType}>
                {
                  types.map((item, index) => {
                    return (
                      <Radio value={item} key={index}>{item}</Radio>
                    );
                  })
                }
              </Radio.Group>
            </div>
            {
              addModal.type !== '回访' &&
              <div className='row-l margin-t10'>
                <div className='w60'>说明：</div>
                <Input value={addModal.remark} className='w300' onChange={this.changeRemark} />
              </div>
            }
            {
              addModal.type === '回访' &&
              <div className='col-t vertical-lt margin-t10'>
                <div className='row-l bottomLine'>
                  <div className='w130'>小程序是否规范:</div>
                  <Radio.Group value={backInfo.normal} onChange={this.changeBack.bind(this, 'normal')} className='w400'>
                    {
                      ['填写时间不准确，需沟通规范', '准确填写，且按时签到', '准确填写，未签到'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>宽度高度描述是否清晰:</div>
                  <Radio.Group value={backInfo.clear} onChange={this.changeBack.bind(this, 'clear')} className='w400'>
                    {
                      ['无描述，需沟通规范', '有描述，不清晰', '有描述，且清晰'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>有窗帘盒，盒宽盒高有无测量:</div>
                  <Radio.Group value={backInfo.box} onChange={this.changeBack.bind(this, 'box')} className='w400'>
                    {
                      ['有测量', '无测量，需沟通规范', '无窗帘盒'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>安装位置:</div>
                  <Radio.Group value={backInfo.site} onChange={this.changeBack.bind(this, 'site')} className='w400'>
                    {
                      ['有测量', '无测量，需沟通规范'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>是否测量影响窗帘使用的内开窗间距:</div>
                  <Radio.Group value={backInfo.space} onChange={this.changeBack.bind(this, 'space')} className='w400'>
                    {
                      ['有测量', '无测量，需沟通规范', '无影响'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>飘窗内外方案:</div>
                  <Radio.Group value={backInfo.outside} onChange={this.changeBack.bind(this, 'outside')} className='w400'>
                    {
                      ['有测量', '无测量，需沟通规范', '无飘窗'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>影响安装/使用的细节备注:</div>
                  <Radio.Group value={backInfo.detail} onChange={this.changeBack.bind(this, 'detail')} className='w400'>
                    {
                      ['有测量', '无测量，需沟通规范', '无影响因素'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>房间照片是否全景/清晰:</div>
                  <Radio.Group value={backInfo.round} onChange={this.changeBack.bind(this, 'round')} className='w400'>
                    {
                      ['有', '无，需沟通规范'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>手稿签字:</div>
                  <Radio.Group value={backInfo.sign} onChange={this.changeBack.bind(this, 'sign')} className='w400'>
                    {
                      ['有手稿，有签字', '有手稿，无签字。需沟通规范'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>沟通内容:</div>
                  <Input.TextArea value={backInfo.talk} onChange={this.changeBack.bind(this, 'talk')} className='w400' rows={2} />
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>是否迟到:</div>
                  <Radio.Group value={backInfo.late} onChange={this.changeBack.bind(this, 'late')} className='w400'>
                    {
                      ['是', '否'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>服务态度:</div>
                  <Radio.Group value={backInfo.attitude} onChange={this.changeBack.bind(this, 'attitude')} className='w400'>
                    {
                      ['好', '不好'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l vertical-lt bottomLine'>
                  <div className='w130'>推销线下产品:</div>
                  <Radio.Group value={backInfo.mark} onChange={this.changeBack.bind(this, 'mark')} className='w400'>
                    {
                      ['是', '否'].map((item, index) => {
                        return (
                          <Radio value={item} key={index}>{item}</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
              </div>
            }
          </Modal>
        </Modal>
      </div>
    );
  }

  getServices = async () => {
    let res = await getServiceList('', '全部', 0, 1000);
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })
    }
  }
  onChangeDate = (type, e) => {
    if (type === 'start') {
      this.setState({
        start: moment(e).format(dateFormat)
      })
    } else {
      this.setState({
        end: moment(e).format(dateFormat)
      })

    }
  }
  changeService = (e) => {
    this.setState({
      serviceType: e
    });
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys
    });
  }
  //批量处理
  onReminder = async () => {
    const { selectedRowKeys, } = this.state;
    let data = {
      ids: selectedRowKeys,
      type: '任务',
    };
    if (selectedRowKeys.length > 0) {
      let res = await delReminder(data);
      if (res.status === 204) {
        this.setState({
          selectedRowKeys: 0
        });
        this.toSearch()
      }

    }
  }
  onShowModal = (id, name) => {
    this.setState({
      visible: true,
      title: name,
      labelDataType: id,
    }, () => this.toSearch())

  }
  onCancel = () => {
    this.setState({
      visible: false,
      selectedRowKeys: [],
      radioType: '待处理',
      serviceType: '全部',
      withMe: true,
    })
    this.props.updateTable();
  }
  removeT = (e) => {
    let date = e.split('T');
    date = date.join(' ');
    return date
  }
  onFollowUp = (e) => {
    this.indexModal.current.onShowModal(e);
    // this.indexModal.current.onShowModal(id, checker, chkName);
  }
  //新增回访
  newRecord = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.visible = true;
    addModal.orderid = e.order;
    addModal.worker = e.worker;
    this.setState({
      addModal,
    });
  }
  changeRemark = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.remark = e.target.value;
    this.setState({
      addModal,
    });
  }
  changeType = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.type = e.target.value;
    this.setState({
      addModal,
    });
  }
  //新增记录
  addRecord = async () => {
    const { addModal, backInfo } = this.state;
    let content = addModal.remark;
    if (addModal.type === '回访') {
      // if (backInfo.normal === '' || backInfo.clear === '' || backInfo.box === '' || backInfo.site === '' ||
      //   backInfo.space === '' || backInfo.outside === '' || backInfo.detail === '' || backInfo.round === '' ||
      //   backInfo.sign === '' || backInfo.late === '' || backInfo.attitude === '' || backInfo.mark === '') {
      //   message.warn('带*号的未选择');
      //   return;
      // } else {
      content = `${backInfo.normal ? '小程序是否规范操作:' + backInfo.normal + ';' : ''}
        ${backInfo.clear ? '宽度高度描述是否清晰:' + backInfo.clear + ';' : ''}
        ${backInfo.box ? '有窗帘盒，盒宽盒高有无测量:' + backInfo.box + ';' : ''}
        ${backInfo.site ? '安装位置:' + backInfo.site + ';' : ''}
        ${backInfo.space ? '是否测量影响窗帘使用的内开窗间距:' + backInfo.space + ';' : ''}
        ${backInfo.outside ? '飘窗内外方案:' + backInfo.outside + ';' : ''}
        ${backInfo.detail ? '影响安装/使用的细节备注:' + backInfo.detail + ';' : ''}
        ${backInfo.round ? '房间照片是否全景/清晰:' + backInfo.round + ';' : ''}
        ${backInfo.sign ? '手稿签字:' + backInfo.sign + ';' : ''}
        ${backInfo.talk ? '沟通内容:' + backInfo.talk + ';' : ''}
        ${backInfo.late ? '是否迟到:' + backInfo.late + ';' : ''}
        ${backInfo.attitude ? '服务态度:' + backInfo.attitude + ';' : ''}
        ${backInfo.mark ? '推销线下产品:' + backInfo.mark + ';' : ''};`;
      // }
    }
    let res = await postAppeal(addModal.orderid, addModal.worker, addModal.type, content);
    if (res.status === 200) {
      this.onCancelAdd();
    }
  }
  onCancelAdd = () => {
    this.setState({
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
        orderid: 0,
        worker: 0,
      },
    });
  }
  updateTable = (id, radioType, textAreaValue, kefuData) => {
    let data = _.cloneDeep(this.state.data);
    let index = data.findIndex(r => r.id === id);
    if (radioType === '已处理') {
      data.splice(index, 1);
    } else {
      data[index].chkName = kefuData.name;
      data[index].checker = kefuData.id;
      data[index].remark = textAreaValue;
    }
    this.setState({
      data: data
    })
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value
    });
  }

  //与我有关
  onCheckbox = (e) => {
    this.setState({
      withMe: e.target.checked,
    }, async () => this.toSearch())
  }
  //处理状态
  onRadio = (e) => {
    this.setState({
      radioType: e.target.value
    }, async () => this.toSearch())
  }
  //切换任务
  onLabelDataClick = (e) => {
    if (this.state.labelDataType === e) { return; }
    this.setState({
      labelDataType: e
    }, async () => this.toSearch())
  }
  toSearch = () => {
    this.page.current.returnFirst();
    this.setState({
      skip: 0,
    }, async () => this.getTaskData());
  }
  //获取任务数据
  getTaskData = async () => {
    let { labelDataType, serviceType, radioType, withMe, info, start, end, skip, take } = this.state;
    let res = await getTaskList({
      label: labelDataType,
      service: serviceType,
      state: radioType,
      mytask: withMe,
      start, end,
      info, skip, take
    });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data
    })
  }
  //任务列表
  getLabelsList = async () => {
    let res = await getLabels();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      labelData: res.data,
      // labelDataType: res.data[0].id,
    }, async () => this.toSearch())
  }
  //跟进记录
  toDetailRecord = (e) => {
    this.detailModal.current.onShow(e);
  }
  //回访
  changeBack = (type, e) => {
    let backInfo = { ...this.state.backInfo };
    backInfo[type] = e.target.value;
    this.setState({
      backInfo
    })
  }
  //换页
  pageMaps = ({ skip, take, }) => {
    this.setState({
      skip: skip,
    }, async () => this.getTaskData())
  }

  toExcel = async () => {
    let { labelDataType, serviceType, radioType, withMe, info, title, start, end, } = this.state;
    const columsTask = [
      {
        title: '订单信息',
        key: 'orderInfo',
      },
      {
        title: '客户信息',
        key: 'customInfo',
      },
      {
        title: '商家信息',
        key: 'shopName',
      },
      {
        title: '师傅信息',
        key: 'workerName',
      },
      {
        title: '处理状态',
        key: 'state',
      },
      {
        title: '标记人',
        key: 'subName',
      },
      {
        title: '提交时间',
        key: 'submit_time',
      },
      {
        title: '标记备注',
        key: 'content',
      },
      {
        title: '处理人',
        key: 'chkName',
      },
      {
        title: '处理时间',
        key: 'check_time',
      },
      {
        title: '处理时效',
        key: 'aging',
      },
      {
        title: '处理备注',
        key: 'remark',
      },
    ];
    let res = await getTaskList({
      label: labelDataType, service: serviceType,
      state: radioType, mytask: withMe,
      start, end, info, skip: 0, take: 0
    });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let excelData = res.data.map((item, index) => {
      let subName = '';
      let exit = this.state.serviceList.filter(r => r.id === item.submitter);
      if (item.submitter > 0 && exit[0]) {
        subName = exit[0].name;
      }
      let row = {
        orderInfo: item.service + '-' + item.order + '\n' + item.category + '-' + item.type + '\n' + item.state,
        customInfo: item.name + '，' + item.phone + '\n' + item.province + item.city + item.district + item.address,
        shopName: item.shopName,
        workerName: item.workerName,
        state: item.state,
        subName: subName,
        submit_time: item.submit_time,
        content: item.content,
        chkName: item.chkName,
        check_time: item.check_time,
        aging: item.state === '已处理' ? ((moment(item.check_time).unix() - moment(item.submit_time).unix()) / 60 / 60).toFixed(1) + 'h' : '',
        remark: item.remark,
      };
      return row;
    })
    let fileName = title + '-' + radioType + '列表';
    let titleName = title + '-' + radioType + '列表';
    jsToExcel(columsTask, excelData, fileName, titleName);
  }
  preview = (e) => {
    let url = this.baseUrl + '/upload/' + e;
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
}

export default Index;