import React, { Component } from 'react';
import { Modal, Select, Radio, Image, Button, message, Input, Table, Spin, InputNumber, Checkbox } from 'antd';

const records = [
  {
    id: 1,
    content: '服务满意获得客户在商家店铺好评，晒照片/视频并带上墨墨管家师傅服务好(窗帘类)',
  },
  {
    id: 2,
    content: '特定商家订单(带“好评”字样)，服务满意获得客户在商家店铺好评，晒照片/视频',
  },
  {
    id: 3,
    content: '测量订单以下单时间为准，数据在12小时内回传的订单(仅限618、双十一、双十二等活动期间)',
  },
  {
    id: 4,
    content: '安装订单以下单时间为准，订单在24小时内完工(仅限618、双十一、双十二等活动期间)',
  },
  {
    id: 5,
    content: '1个月(月初至月末)内0投诉，0售后(只对签约师傅有效)',
  },
  {
    id: 6,
    content: '售后单下单后12小时内完工(仅限618、双十一、双十二等活动期间)',
  },
  {
    id: 7,
    content: '售后单下单后48小时内未完工(仅限618、双十一、双十二等活动期间)',
  },
  {
    id: 8,
    content: '服务良好，真实感动用户，有正面影响力，得到下单商家点名好评师傅服务好的',
  },
  {
    id: 9,
    content: '积极有效的配合处理严重用户投诉、敏感事件，如315或媒体投诉，降低事件不良影响程度，控制事态发展',
  },
  {
    id: 10,
    content: '派单后3小时内未预约罚款5元;派单后4小时内未预约罚款10元;以罚款5元为基础依次类推最高罚款20元',
  },
  {
    id: 11,
    content: '预约时间前后一小时内未到达服务点签到',
  },
  {
    id: 12,
    content: '在安装现场抽烟、随意使用客户家物品、安装完不处理安装垃圾引起投诉',
  },
  {
    id: 13,
    content: '未经允许私自向客户收取订单额外费用，除退回费用',
  },
  {
    id: 14,
    content: '预约时间时未与客户确认服务地址和下单地址是否一致，由师傅承担地址错误导致的空跑费用',
  },
  {
    id: 15,
    content: '上门前未与客户确认是否装修完成，到达现场无法测量导致的空跑，由师傅承担二次上门费用',
  },
  {
    id: 16,
    content: '上门前未与客户确认客户家窗帘和杆轨是否到齐，到达现场无法安装导致的空跑，由师傅承担二次上门费用',
  },
  {
    id: 17,
    content: '迟到/爽约/虚假预约，造成客户投诉',
  },
  {
    id: 18,
    content: '现场讨论工费、产品价格、产品质量等引起投诉',
  },
  {
    id: 19,
    content: '服务偷工减料，如罗马杆支架有两个孔位，只打一个螺丝，造成商家或客户严重投诉',
  },
  {
    id: 20,
    content: '现场问题未反馈，导致二次上门核实问题，由师傅承担二次上门费用',
  },
  {
    id: 21,
    content: '因师傅服务质量问题导致的投诉，一个月内连续被投诉三次，停单五天起',
  },
  {
    id: 22,
    content: '与客户发生肢体冲突/恶性辱骂/威胁/骚扰客户，性质恶劣',
  },
  {
    id: 23,
    content: '翘单、推销产品、引导劝诱客户走私单等不正当行为',
  },

];
class RewardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
    };
    this.columns = [
      {
        title: '原因',
        dataIndex: 'content',
        align: 'left',
        width: 500,
      },
    ];
  }
  render() {
    const { visible, selectedRowKeys, } = this.state;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'radio',
      onChange: this.onSelectChange,
      columnWidth: 50,
    };
    return (
      <Modal
        title={'奖惩条例'}
        width={600}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel} type='ghost'>取消</Button>
            <Button onClick={this.onOk} type='primary'>确认</Button>
          </div>
        }
      >
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={records}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={{ defaultPageSize: 10 }}
        />
      </Modal>
    )
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys, selectedRows
    });
  }
  onShow = () => {
    this.setState({
      visible: true
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
    });
  }
  onOk = () => {
    const { selectedRowKeys, selectedRows } = this.state;
    if (selectedRowKeys.length === 0) {
      message.warn('未选择原因');
      return;
    } else {
      this.props.selectReward(selectedRows[0].content);
      this.onCancel();
    }

  }
}
export default RewardModal;