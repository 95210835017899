import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, Popconfirm, } from 'antd';
import { getNotice, addNotice, editNotice, delNotice } from '../../services/noticeManagement';
import PageTurning from '../../components/pageTurning';
import moment from 'moment';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        enable: '全部',
        search: '',
        skip: 0,
        take: 10,
      },
      dataSource: [],
      informModal: false,
      inform: {
        id: 0,
        type: '通知',
        name: '',
        content: '',
        url: '',
        enable: '是',
        ascription: '师傅端',
      },
    };
    this.pageModal = React.createRef();
    this.colums = [
      {
        title: '标题',
        align: 'center',
        width: 300,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '内容',
        align: 'center',
        width: 600,
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 300,
        dataIndex: 'enable',
        key: 'enable',
      },
      {
        title: '发送时间',
        align: 'center',
        width: 300,
        dataIndex: 'submit_time',
        key: 'submit_time',
        render: (text, record, index) => {
          return (<div>{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 300,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='w100 row'>
              <div onClick={this.editInform.bind(this, record)} className={`${styles['btn']} back-color8 margin-r10 color0 cursor-pointer`}>编辑</div>
              <div onClick={this.deleteNotice.bind(this, record)} className={`${styles['btn']} back-color11 color0 cursor-pointer`}>删除</div>
            </div>
          );
        }
      },
    ];
  }
  componentDidMount() {
    this.getList();
  }
  render() {
    const { info, dataSource, inform, informModal, } = this.state;
    return (
      <div className='col-t w100'>
        <div className='row-l vertical-lt padding20 back-color0 w100'>
          <div className=''>启用状态：</div>
          <Select onSelect={this.changeSummary.bind(this, 'enable')} value={info.enable} className='w110'>
            {
              ['全部', '启用', '禁用'].map((item, indexs) => {
                return (
                  <Option key={indexs} value={item}>{item}</Option>
                );
              })
            }
          </Select>
          <div className='margin-l20'>搜索：</div>
          <Input value={info.search} className='w250' onChange={this.changeSummary.bind(this, 'search')} />
          <Button onClick={this.getList} type='primary'>搜索</Button>
        </div>
        <Table
          columns={this.colums}
          dataSource={dataSource}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
          className='w100'
        />
        <div className='back-color0 w100'>
          <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.pageModal} />
        </div>
        <Modal
          title={'编辑奖惩'}
          visible={informModal}
          width={500}
          okText='确认'
          cancelText='取消'
          onCancel={this.hideInformModal}
          onOk={this.addNewNotice}
        >
          <div className='col-t vertical-lt w100'>
            <div className='row-l margin-b20'>
              <div className='w80'>{inform.type === '通知' ? '通知标题：' : '资料名称：'}</div>
              <Input disabled value={inform.name} className='w360' />
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>启用状态：</div>
              <Radio.Group onChange={this.changeSummary2.bind(this, 'addEnable')} value={inform.enable === '是' ? '启用' : '禁用'} size='large'>
                {
                  ['启用', '禁用'].map((item, index) => {
                    return <Radio value={item} key={index} checked={inform.enable === '是' && item === '启用'} className='fz16 margin-l5'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>{inform.type === '通知' ? '通知内容：' : '资料简介：'}</div>
              <Input.TextArea onChange={this.changeSummary2.bind(this, 'content')} value={inform.content} placeholder={inform.type === '通知' ? '请输入内容' : '请输入简介'} className='w360 h120' />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  changeSummary = (type, e) => {
    let info = { ...this.state.info };
    switch (type) {
      case 'enable':
        info[type] = e;
        break;
      case 'search':
        info[type] = e.target.value;
        break;
    }
    this.setState({
      info
    })
  }
  getList = async () => {
    const { info } = this.state;
    let res = await getNotice(info.enable, '全部', '奖惩', info.search, info.skip, info.take);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data,
      });
    }
  }
  pageMaps = ({ skip, take }) => {
    let info = { ...this.state.info };
    info.skip = skip;
    this.setState({
      info,
    }, () => this.getList())
  }
  deleteNotice = (e) => {
    Modal.confirm({
      title: '确认删除此项通知吗？',
      okText: '是',
      cancelText: '否',
      onOk: async () => {
        let res = await delNotice(e.id);
        if (res.status === 204) {
          this.getNoticeList()
        }
      }
    })
  }
  editInform = (e) => {
    this.setState({
      inform: e,
      informType: 'edit',
      informModal: true,
    })
  }
  changeSummary2 = (type, e) => {
    let inform = { ...this.state.inform };
    switch (type) {
      case 'content':
        inform.content = e.target.value;
        this.setState({
          inform
        });
        break;
      case 'addEnable':
        inform.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          inform
        });
        break;
    }
  }
  addNewNotice = async () => {
    const { informType, inform } = this.state;
    if (inform.name === '') {
      message.error('标题未填写');
      return;
    }
    let res = await editNotice(inform.id, inform);
    if (res.status !== 204) { return; }
    this.hideInformModal()
    this.getList()
  }
  hideInformModal = () => {
    this.setState({
      informModal: false,
      inform: {
        id: 0,
        type: '通知',
        name: '',
        content: '',
        url: '',
        enable: '是',
        ascription: '师傅端',
      },
    });
  }
}
export default Index;