import { message, Table, Input, Button, DatePicker } from 'antd';
import React, { Component } from 'react';
import Search from '../../../components/financeSearch';
import PageTurning from '../../../components/pageTurning';
import { getWorkerflist } from '../../../services/orderFinance';
import CreditCard from '../../../resource/png/credit-card.png';
import Person from '../../../resource/png/person.png';
import Weixinzhifu from '../../../resource/png/weixinzhifu.png';
import Alipay from '../../../resource/png/alipay.png';
import Link from '../../../resource/png/masterManagement-link.png';
import styles from './index.module.css';
import IndexModal from './indexModal';
import { correctExcel } from '../../../utils/exportExcel';
import { getDepartment_Data } from '../../../config/storage';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const format = 'YYYY-MM-DD';
const today = moment().format(format);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skip: 0,
      take: 10,
      day: today,
      searchType: '',
      download: false,
    }
    this.role = getDepartment_Data().roles[0];
    this.indexModal = React.createRef();
    this.pageTurning = React.createRef();
    this.columns = [
      {
        title: '师傅基本信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='col vertical-lt margin-l10'>
              <div className='row-l'>
                <img src={Person} />
                <div className='margin-r10 color3 underline '>{record.name}</div>
                <div className='color7'>{record.uid}</div>
                <div className='margin-l10 color7'>(账号)</div>
                <img src={Link} className='margin-l10' onClick={this.onCopyToClip.bind(this, record)} />
              </div>
              <div className='color7'>{record.current_address}</div>
            </div>
          )
        }
      },
      {
        title: '余额',
        align: 'center',
        width: 100,
        dataIndex: 'balance',
        render: (text, record, index) => {
          return (
            <div className={record.balance < 0 ? 'color2 bold fz18' : ''}>{record.balance}</div>
          )
        }
      },
      {
        title: '已审核余额',
        align: 'center',
        width: 100,
        dataIndex: 'checkBalance',
        render: (text, record, index) => {
          return (
            <div className={record.checkBalance < 0 ? 'color2 bold fz18' : ''}>{record.checkBalance}</div>
          )
        }
      },
      {
        title: '质保金',
        align: 'center',
        width: 100,
        dataIndex: 'deposit',
      },
      {
        title: '当前提现账户',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>
              {
                record.accounts.map((item, index) => {
                  return (
                    <div key={index} className='row-l color7 margin-l10'>
                      {item.type === '支付宝' && <img src={Alipay} />}
                      {item.type === '微信' && <img src={Weixinzhifu} />}
                      {item.type !== '微信' && item.type !== '支付宝' && <img src={CreditCard} />}
                      <div className='margin-l10'>收款账号:</div>
                      <div className='margin-l5'>{item.card_number}</div>
                      <div className='margin-l10'>实名绑定:</div>
                      <div className='margin-l5'>{item.name}</div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              {/* <div className={styles['button1']}>查看师傅信息</div> */}
              {/* <div className='row-l w100'> */}
              {
                this.role !== 5 &&
                <div className={`${styles['button2']} ${styles['color1']}`} onClick={this.onWorkerClick.bind(this, '调整', record.id)}>调整</div>
              }
              <div className={`${styles['button2']} ${styles['color2']} margin-l5`} onClick={this.onWorkerClick.bind(this, '提现', record.id)}>提现申请</div>
              {/* </div> */}
            </div>
          )
        }
      },
    ]
  }

  componentDidMount() {
    this.getList();
  }

  render() {
    const { data, searchType, download, day } = this.state;
    return (
      <div>
        <div className='col back-color0 w100 padding20 vertical-lt'>
          <div className='w100 row-sb'>
            <div className='row-l'>
              <div>日期：</div>
              <DatePicker placeholder='开始时间' className='w198 margin-r20' value={moment(day, format)}
                onChange={this.onChangeDate} locale={locale} clearIcon={false} />
              <div>师傅信息：</div>
              <Input placeholder={'请输入姓名或手机号码'} className={'margin-r20 w295'} value={searchType} onChange={this.onInputValue} />
              <Button type='primary' className={'margin-r20'} onClick={this.onSearch}>搜索</Button>
              <Button onClick={this.onReset}>重置</Button>
            </div>
            <Button onClick={this.toExcel} loading={download} type='primary'>余额导出</Button>
          </div>
        </div>
        <div className='margin-t10 back-color0'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageTurning} />
        </div>
        <IndexModal
          ref={this.indexModal}
          updateTable={this.getList}
        />
      </div>
    );
  }
  onChangeDate = (e) => {
    this.setState({
      day: moment(e).format(format)
    });
  }
  onInputValue = (e) => {
    this.setState({
      searchType: e.target.value,
    })
  }
  onSearch = () => {
    this.pageTurning.current.returnFirst();
    this.setState({
      skip: 0,
    }, () => this.getList());
  }

  onWorkerClick = (e, id) => {
    this.indexModal.current.onShowModal(e, id);
  }
  onReset = () => {
    this.pageTurning.current.returnFirst();
    this.setState({
      searchType: '',
      skip: 0,
    }, async () => this.onSearch())
  }

  getList = async () => {
    let { searchType, day, skip, take } = this.state;
    let res = await getWorkerflist({ date: day, info: searchType, skip: skip, take: take });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data,
    })
  }
  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'姓名:' + data.name + '\n' + '账号:' + data.uid + '\n' + '地址:' + data.current_address}`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  //换页
  pageMaps = ({ skip, take, }) => {
    this.setState({
      skip: skip,
    }, () => this.getList())
  }

  toExcel = async () => {
    let skip = 0;
    let take = 1000;
    let dataSource = [];
    let down = true;
    this.setState({
      download: true,
    })
    while (down === true) {
      let res = await getWorkerflist({ info: '', skip: skip, take: take });
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }
    let title = [
      {
        title: '姓名',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '电话',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: '余额',
        key: 'balance',
        dataIndex: 'balance',
      },
      {
        title: '已审核余额',
        key: 'checkBalance',
        dataIndex: 'checkBalance',
      },
      {
        title: '质保金',
        key: 'deposit',
        dataIndex: 'deposit',
      },
    ];
    // 师傅姓名电话+余额+结算时间
    let data = dataSource.map(item => {
      return {
        name: item.name,
        phone: item.uid,
        balance: item.balance,
        checkBalance: item.checkBalance,
        deposit: item.deposit,
      };
    });
    correctExcel(title, data, '师傅余额');
    this.setState({
      download: false,
    })

  }
}

export default Index;