import React, { Component } from 'react';
import { Button, Modal, Input, message, Upload, Popconfirm, Table, Checkbox } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import WorkerModal from '../allOrder/workerModal';
import ManegerModal from '../../../components/managerModal/index.jsx';
import { ossUpload, } from '../../../utils/ossUpload.js';
import { getVideoInfo, getVideoPlay, getVideoSub, } from '../../../services/newOder';
import { postAllAppeal, getMasterManager } from '../../../services/orderCore';
import convert from '../../../utils/convert';
import { getDepartment_Data } from '../../../config/storage';
import _ from 'lodash';

const model = {
  orderId: 0,
  type: '',
  appeal: 0,
  appealName: '',
  content: '',
  pic: [],
  vod: [],
  dealers: [],
  asyncShop: false,
  asyncWorker: true,
};
class ComplainModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: _.cloneDeep(model),
      current: '',
      currentInfo: {},
    }
    this.workerModal = React.createRef();
    this.manegerModal = React.createRef();//选择客服
    this.manegerModal2 = React.createRef();//选择客服
    this.baseUrl = getDepartment_Data().service;
  }
  render() {
    const { visible, data, } = this.state;
    return (
      <Modal
        title={'订单投诉'}
        visible={visible}
        width={900}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
      >
        <div className='col-t vertical-lt w100'>
          <div className='row-l margin-b10'>
            <div className='w90'>投诉对象：</div>
            <div>外部投诉：</div>
            <Input disabled value={data.type === '外部投诉' ? data.appealName : ''} className='w150' />
            <Button onClick={this.toChoose.bind(this, '外部投诉', '师傅')} type='primary'>选择</Button>
            <div className='margin-l20'>内部投诉：</div>
            <Input disabled value={data.type === '内部投诉' ? data.appealName : ''} className='w150' />
            <Button onClick={this.showManager.bind(this, '内部投诉')} type='primary'>选择</Button>
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div className='w90'>投诉内容：</div>
            <Input.TextArea value={data.content} onChange={this.changeInfo.bind(this, 'content')} className='w600' rows={3} />
          </div>
          <div className='margin-b10'>
            <div className='w90 margin-b10'>其他备注：</div>
            <div className='row-l vertical-lt margin-b10'>
              <div className='w90 tr'>图片：</div>
              <div className='row-w w750'>
                <Upload
                  type='file'
                  beforeUpload={this.onAddPic}
                  fileList={[]}
                  accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                  // accept='image/*'
                  multiple={true}
                >
                  <div className={`row cursor-pointer ${styles['pic-div']}`}>
                    <div className='row h100'>
                      <PlusOutlined />
                      <span>
                        新增图片
                      </span>
                    </div>
                  </div>
                </Upload>
                {
                  data.pic.map((item, index) => {
                    return (
                      <div key={index} className={`${styles['content']}`}>
                        <div className={`row ${styles['pic-icon']}`}
                          onClick={this.delSignPic.bind(this, index)}
                        >
                          <DeleteOutlined />
                        </div>
                        <img
                          alt='暂无图片'
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item}`}
                        />
                        <div className={`row ${styles['content-div']}`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme='outlined'
                            title='预览'
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <div className='row-l vertical-lt margin-b10'>
              <div className='w90 tr'>视频：</div>
              <div className='row-w w750'>
                <Upload
                  type='file'
                  beforeUpload={this.uploadVideo}
                  customRequest={() => { }}
                  fileList={[]}
                  multiple={false}
                  maxCount={1}
                >
                  <div className={`row cursor-pointer ${styles['pic-div']}`}>
                    <div className='row h100'>
                      <PlusOutlined />
                      <span>
                        新增视频
                      </span>
                    </div>
                  </div>
                </Upload>
                {
                  data.vod.map((item, index) => {
                    return (
                      <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                        <div className={`${styles['content-video']}`}>
                          <video controls='controls' className={styles['content-image']} src={item.url} />
                        </div>
                        <Popconfirm
                          title='您确定要删除该视频吗?'
                          onConfirm={this.delVideo.bind(this, index)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                        </Popconfirm>
                      </div>
                    );
                  })
                }
              </div>
            </div>

          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div className='w90'>处理人员：</div>
            <div className='bord1 w500 h90 row-w padding10 scroll-y'>
              {
                data.dealers.map((item, index) => {
                  return (
                    <div className='row bord1 radius5 padding5 margin-r10 margin-b10' key={index}>
                      <div className='margin-r10'>{item.name}</div>
                      <div onClick={this.delDealer.bind(this, index)} className='padding-lr10 cursor-pointer none-select'>X</div>
                    </div>
                  );
                })
              }
            </div>
            <Button onClick={this.addDealer} type='primary'>添加</Button>
          </div>
          <div className='row-l'>
            <div className='w90'></div>
            <div className='row-l'>
              <Checkbox checked={data.asyncShop} onChange={this.changeInfo.bind(this, 'asyncShop')} className='margin-r20'>同步给商家负责人2</Checkbox>
              <Checkbox checked={data.asyncWorker} onChange={this.changeInfo.bind(this, 'asyncWorker')}>同步给师傅负责人2</Checkbox>
            </div>
          </div>
        </div>
        <WorkerModal
          ref={this.workerModal}
          single={true}
          updateTable={this.changeCustom}
        />
        <ManegerModal
          ref={this.manegerModal}
          selectManager={this.selectManager}
        />
        <ManegerModal
          ref={this.manegerModal2}
          selectManager={this.selectManager2}
        />
      </Modal>
    );

  }
  changeInfo = (type, e) => {
    let data = _.cloneDeep(this.state.data);
    if (type === 'content') {
      data.content = e.target.value;
    } else {
      data[type] = e.target.checked;
    }
    this.setState({
      data,
    });
  }
  //客服
  showManager = (current) => {
    this.manegerModal.current.onShow(false);
    this.setState({
      current,
    });
  }
  selectManager = (e) => {
    const { current } = this.state;
    let data = _.cloneDeep(this.state.data);
    data.type = current;
    data.appeal = e[0].id;
    data.appealName = e[0].name;
    data.dealers = [];
    this.setState({
      data
    })
  }
  addDealer = () => {
    this.manegerModal2.current.onShow(true);
  }
  selectManager2 = (e) => {
    let data = _.cloneDeep(this.state.data);
    e.map(item => {
      let exit = data.dealers.findIndex(r => r.id === item.id);
      if (exit < 0) {
        data.dealers.push(item);
      }
    })
    this.setState({
      data,
    });
  }
  //选师傅客服
  toChoose = (current, type) => {
    this.workerModal.current.onShowModal(type);
    this.setState({
      current,
    });
  }
  changeCustom = async (e, type) => {
    const { current } = this.state;
    let data = _.cloneDeep(this.state.data);
    data.type = current;
    data.appeal = e.id;
    data.appealName = e.name;
    data.dealers = [];
    if (type === '师傅') {
      let rsp = await getMasterManager(e.id)
      if (rsp.status === 200) {
        if (rsp.data[0]) {
          data.dealers.push({
            id: rsp.data[0].id,
            name: rsp.data[0].name,
          });
        }
      }
    }
    this.setState({
      data,
    });
  }
  //图片备注
  onAddPic = async (file, fileList) => {
    let data = _.cloneDeep(this.state.data)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = data.pic.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      data.pic[leng + i] = name;
    }
    this.setState({
      data
    })
  }
  delSignPic = (e) => {
    let data = _.cloneDeep(this.state.data)
    data.pic.splice(e, 1);
    this.setState({
      data,
    });
  }
  //视频备注
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let data = _.cloneDeep(this.state.data);
      data.vod.push({
        id: e,
        url: res.data.playURL,
      });
      this.setState({
        data
      });
    }
  }
  delVideo = (index) => {
    let data = _.cloneDeep(this.state.data);
    data.vod.splice(index, 1)
    this.setState({
      data
    });
  }
  //处理人
  delDealer = (index) => {
    let data = _.cloneDeep(this.state.data);
    data.dealers.splice(index, 1)
    this.setState({
      data
    });
  }


  onOk = async () => {
    const { data, currentInfo } = this.state;
    if (data.type === '') {
      message.warn('未选择投诉对象');
      return;
    }
    if (data.dealers.length === 0) {
      message.warn('未选择处理人员');
      return;
    }
    let newData = {
      orderId: currentInfo.id,
      type: data.type === '外部投诉' ? '投诉师傅' : '投诉客服',
      appeal: data.appeal,
      content: data.content,
      pic: JSON.stringify(data.pic),
      vod: [],
      dealers: data.dealers,
      asyncShop: data.asyncShop,
      asyncWorker: data.asyncWorker,
    };
    let vods = data.vod.map(r => r.id);
    newData.vod = JSON.stringify(vods);
    let res = await postAllAppeal(newData);
    if (res.status === 204) {
      this.onCancel();
    }
  }
  onShow = async (e) => {
    let data = _.cloneDeep(model);
    data.type = '外部投诉';
    data.appeal = e.workerId;
    data.appealName = e.workerName;
    let rsp = await getMasterManager(e.workerId)
    if (rsp.status === 200) {
      if (rsp.data[0]) {
        data.dealers.push({
          id: rsp.data[0].id,
          name: rsp.data[0].name,
        });
      }
    }
    this.setState({
      visible: true,
      currentInfo: e,
      data,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      data: _.cloneDeep(model),
      current: '',
      currentInfo: {},
    });
  }
  preview = (url) => {
    let features = 'height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no';
    window.open(url, 'newW', features);
  }
}
export default ComplainModal;