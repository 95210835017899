import request from '../utils/request';

//测量数据列表
export async function getMeasureList(state, info, myarea, shopid, user, shoptype, skip, take, start, end) {
  return await request(`platbiz/measurelist?state=${state}&info=${info}&myarea=${myarea}&shopid=${shopid}&user=${user}&shoptype=${shoptype}&skip=${skip}&take=${take}&start=${start}&end=${end}`, 'get');
}
//数据整理上传
export async function putDealdata(data) {
  return await request('platbiz/dealmesure', 'put', data);
}
// export async function putDealdata(orderid, isasync, isSelf, type, remark, data) {
//   return await request(`platbiz/dealdata?orderid=${orderid}&isasync=${isasync}&isSelf=${isSelf}&type=${type}&remark=${remark}`, 'put', data);
// }