import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Drawer, Popover, Select, Modal, Button, DatePicker, Checkbox } from 'antd';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { KeyOutlined, } from '@ant-design/icons';
import { getDepartment_Data } from '../../config/storage.js';
import { getShopSummary, getShopOrder, getShopBill } from '../../services/analysisCenter.js';
import { getServiceList } from '../../services/serviceManagement.js';
import PageTurning from '../../components/pageTurning/index.jsx';
import ManegerModal from '../../components/managerModal/index.jsx';
import { jsToExcel2, correctExcel } from '../../utils/exportExcel.js';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const selectProp = ['正常测量', '正常安装', '二次测量', '二次安装', '售后测量', '售后安装', '充值汇总', '赠送汇总', '消费汇总', '变更汇总', '好评汇总', '退款汇总', '理赔汇总', '修正汇总'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        servicer: 0,
        servicerName: '全部',
        start: '',
        end: '',
      },
      dataSourceShop: [],

      summary: {
        orderNum: 0,
        recharge: 0,
        pay: 0,
        change: 0,
        refound: 0,
        fix: 0,
        present: 0,
        bonus: 0,
        aftersale: 0,
        sale_num: 0,
        stop_num: 0,
        sameRate: 0,
        fin_aft_ins: 0,
        fin_aft_ms: 0,
        fin_nor_ins: 0,
        fin_nor_ms: 0,
        fin_sec_ins: 0,
        fin_sec_ms: 0,
      },
      shopInfo: {
        currentShop: 0,
        currentShopName: '',
        start: '',
        end: '',
      },
      dataSourceOrder: [],
      count: {
        '正常测量': 0,
        '正常安装': 0,
        '二次测量': 0,
        '二次安装': 0,
        '售后测量': 0,
        '售后安装': 0,
        '订单测量': 0,
        '订单安装': 0,
        '充值汇总': 0,
        '赠送汇总': 0,
        '消费汇总': 0,
        '变更汇总': 0,
        '退款汇总': 0,
        '理赔汇总': 0,
        '修正汇总': 0,
      },
      quickBtn: 0,
      currentProp: selectProp[0],

      billVisible: false,
      skip: 0,
      take: 10,
      billSource: [],
    };
    this.pageModal = React.createRef();
    this.role = getDepartment_Data().roles[0];
    this.manegerModal = React.createRef();//选择客服
  }
  componentDidMount() {
    let start = moment(new Date()).subtract(31, 'days');
    let end = moment(new Date());
    this.setState({
      searchInfo: {
        servicer: 0,
        servicerName: '全部',
        start,
        end,
      },
      shopInfo: {
        currentShop: 0,
        currentShopName: '',
        start,
        end,
      },
    }, () => this.getServicerShop());
  }
  render() {
    const { dataSourceOrder, searchInfo, dataSourceShop, shopInfo, summary, count,
      quickBtn, currentProp, billVisible, billSource } = this.state;
    const shopColums = [
      {
        title: '商家名称',
        align: 'center',
        width: 220,
        dataIndex: 'name',
        key: 'name',
        className: 'padding5',
        fixed: 'left',
        render: (text, record, index) => {
          return (
            <div className='row-sb w100'>
              <div className='w140 oneline'>{record.name}</div>
              <div className={`${styles['btn-look']} ${styles[shopInfo.currentShop === record.id ? 'btn-look-select2' : 'btn-look-select1']}`}
                onClick={this.selectShop.bind(this, record)}
              >查看</div>
            </div>
          );
        },
        filterResetToDefaultFilteredValue: true,
        ...this.getColumnSearchProps('name'),
      },
      {
        title: '订单汇总(单)',
        align: 'center',
        width: 130,
        dataIndex: 'orderNum',
        key: 'orderNum',
        className: 'padding5',
        // render: (text, record, index) => {
        //   return (
        //     <Popover
        //       content={
        //         <div className='col-t vertical-lt'>
        //           <div>测量订单：{record.measure_num}</div>
        //           <div>安装订单：{record.install_num}</div>
        //         </div>
        //       }
        //     >
        //       <div className='cursor-pointer'>{record.orderNum}</div>
        //     </Popover>
        //   );
        // },
        sorter: {
          compare: (a, b) => a.orderNum - b.orderNum
        },
        defaultSortOrder: 'orderNum'
      },
      {
        title: '充值汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'recharge',
        key: 'recharge',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.recharge - b.recharge
        }
      },
      {
        title: '赠送汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'present',
        key: 'present',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.present - b.present
        }
      },
      {
        title: '消费汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'pay',
        key: 'pay',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.pay - b.pay
        },
        // render: (text, record, index) => {
        //   let shopPrice = record.pay + record.change + record.fix;
        //   let workPrice = record.cost;
        //   return (
        //     <div>{shopPrice},{workPrice}{(shopPrice - workPrice) / shopPrice}</div>
        //   );
        // }
      },
      {
        title: '变更汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'change',
        key: 'change',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.change - b.change
        }
      },
      {
        title: '好评汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'bonus',
        key: 'bonus',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.bonus - b.bonus
        }
      },
      {
        title: '退款汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'refound',
        key: 'refound',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.refound - b.refound
        }
      },
      {
        title: '理赔汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'aftersale',
        key: 'aftersale',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.aftersale - b.aftersale
        }
      },
      {
        title: '修正汇总(元)',
        align: 'center',
        width: 130,
        dataIndex: 'fix',
        key: 'fix',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fix - b.fix
        }
      },
      {
        title: '利润率1',
        align: 'center',
        width: 130,
        dataIndex: 'profit',
        key: 'profit',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div>{record.profit ? Number(record.profit * 100).toFixed(1) + '%' : 0}</div>
          );
        },
        sorter: {
          compare: (a, b) => a.profit - b.profit
        }
      },
      {
        title: '利润率2',
        align: 'center',
        width: 130,
        dataIndex: 'profit2',
        key: 'profit2',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div>{record.profit2 ? Number(record.profit2 * 100).toFixed(1) + '%' : 0}</div>
          );
        },
        sorter: {
          compare: (a, b) => a.profit2 - b.profit2
        }
      },
      {
        title: '正常测量(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_nor_ms',
        key: 'fin_nor_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_nor_ms - b.fin_nor_ms
        }
      },
      {
        title: '正常安装(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_nor_ins',
        key: 'fin_nor_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_nor_ins - b.fin_nor_ins
        }
      },
      {
        title: '二次测量(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_sec_ms',
        key: 'fin_sec_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_sec_ms - b.fin_sec_ms
        }
      },
      {
        title: '二次安装(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_sec_ins',
        key: 'fin_sec_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_sec_ins - b.fin_sec_ins
        }
      },
      {
        title: '售后测量(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_aft_ms',
        key: 'fin_aft_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_aft_ms - b.fin_aft_ms
        }
      },
      {
        title: '售后安装(单)',
        align: 'center',
        width: 130,
        dataIndex: 'fin_aft_ins',
        key: 'fin_aft_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.fin_aft_ins - b.fin_aft_ins
        }
      },
      {
        title: '终止订单(单)',
        align: 'center',
        width: 130,
        dataIndex: 'stop_num',
        key: 'stop_num',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.stop_num - b.stop_num
        }
      },

    ];
    const columsBill = [
      {
        title: '流水号',
        align: 'center',
        width: 110,
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '订单编号',
        align: 'center',
        width: 110,
        dataIndex: 'order',
        key: 'order',
      },
      {
        title: '订单类型',
        align: 'center',
        width: 110,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '商户名称',
        align: 'center',
        width: 110,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '费用类型',
        align: 'center',
        width: 110,
        dataIndex: 'service_type',
        key: 'service_type',
      },
      {
        title: '发生金额',
        align: 'center',
        width: 110,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '账户金额',
        align: 'center',
        width: 110,
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: '结算时间',
        align: 'center',
        width: 150,
        dataIndex: 'check_time',
        key: 'check_time',
        render: (text, record, index) => {
          return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 220,
        dataIndex: 'order_info',
        key: 'order_info',
        render: (text, record, index) => {
          return (
            <div className='lineCut w100'>{record.order_info}</div>
          );
        }
      },
      {
        title: '备注信息',
        align: 'center',
        width: 220,
        dataIndex: 'content',
        key: 'content',
      },
    ];
    let colums = this.getPropColumns(currentProp)
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='margin-r10'>商家客服：</div>
              <div className='w160 bord3 none-select h32 tc'>{searchInfo.servicerName}</div>
              <Button onClick={this.showManager} type='primary'>选择</Button>
              <div className={`${styles['tit']} tr`}>结算时间：</div>
              <DatePicker onChange={this.changeTime.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} clearIcon={false} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeTime.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} clearIcon={false} />
              <Button onClick={this.getServicerShop} type='primary' className='margin-l20 margin-r10'>搜索</Button>
            </div>
            <div className='row'>

              <Button onClick={this.toExcel2} type='primary'>导出excel</Button>
              <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
            </div>
          </div>
        </div>
        <div className='w100 col margin-t10 margin-b10'>
          <div className={`${styles['title']} tl w100 bold fz16`}>统计汇总</div>
          <div className='row-sa w100 back-color0 h90'>
            <Popover
              content={
                <div className='col-t vertical-lt'>
                  <div>正常测量:{summary.fin_nor_ms}</div>
                  <div>正常安装:{summary.fin_nor_ins}</div>
                  <div>二次测量:{summary.fin_sec_ms}</div>
                  <div>二次安装:{summary.fin_sec_ins}</div>
                  <div>售后测量:{summary.fin_aft_ms}</div>
                  <div>售后安装:{summary.fin_aft_ins}</div>
                  <div>终止订单:{summary.stop_num}</div>
                </div>
              }
            >
              <div className={`${styles['singleSum']} col cursor-pointer`}>
                <div>{summary.orderNum}</div>
                <div>订单汇总（单）</div>
              </div>
            </Popover>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '充值')}>
              <div>{summary.recharge.toFixed(1)}</div>
              <div>充值汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '赠送')}>
              <div>{summary.present.toFixed(1)}</div>
              <div>赠送汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.pay.toFixed(1)}</div>
              <div>消费汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '变更')}>
              <div>{summary.change.toFixed(1)}</div>
              <div>变更汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '好评')}>
              <div>{summary.bonus.toFixed(1)}</div>
              <div>好评汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '退款')}>
              <div>{summary.refound.toFixed(1)}</div>
              <div>退款汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '理赔')}>
              <div>{summary.aftersale.toFixed(1)}</div>
              <div>理赔汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col cursor-pointer color3`} onClick={this.onShowBill.bind(this, '修正')}>
              <div>{summary.fix.toFixed(1)}</div>
              <div>修正汇总（元）</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.shopPrice ? ((-summary.shopPrice - summary.workPrice) / (-summary.shopPrice) * 100).toFixed(1) : 0}%</div>
              <div>利润率1</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.shopPrice2 ? ((-summary.shopPrice2 - summary.workPrice2) / (-summary.shopPrice2) * 100).toFixed(1) : 0}%</div>
              <div>利润率2</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.pay ? (summary.aftersale / summary.pay * 100).toFixed(1) : 0}%</div>
              <div>差错率</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.sameRate}</div>
              <div>转化率</div>
            </div>
          </div>
        </div>
        <div className='w100 back-color0 margin-b10'>
          <Table
            columns={shopColums}
            dataSource={dataSourceShop}
            size={'small'}
            rowKey={record => record.id}
            bordered
            scroll={{
              x: 'max-content',
            }}
          />
        </div>
        <div className='row-sb back-color0 vertical-lt w100'>
          <div className='w250'>
            <Select value={currentProp}
              onChange={this.changeProp}
              className='w250 margin-b5'
            >
              {selectProp.map((item, index) => {
                return <Select.Option value={item} key={index}>{item}</Select.Option>;
              })}
            </Select>
            <Table
              columns={colums}
              dataSource={dataSourceOrder}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
            <Button onClick={this.toExcel3} type='primary'>导出</Button>
          </div>
          <div className='back-color0 padding20'>
            <div className='row-sb padding-tb10'>
              <div className='row-l'>
                <div className='fz18 bold'>{shopInfo.currentShopName ? shopInfo.currentShopName + '-每日' + currentProp : ''}</div>
                <div className={`${styles['selectDate']} color7`}>快速选择：</div>
                <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
                <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
                <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
                <div onClick={this.quickSelect.bind(this, 0)} className={quickBtn === 0 ? styles['quickly-select'] : styles['quickly']}>重置</div>
              </div>
              <div className='row-r'>
                <div className='color7'>汇总：</div>
                <div className={`${styles['summary']} row`}>
                  <div className='bold'>{count[currentProp]}</div>
                  <div className='color5'>（ {currentProp.slice(0, 2)}）</div>
                </div>
              </div>
            </div>
            <div className='padding-b20'>
              <div className='h340 w1300' id='main'></div>
            </div>
          </div>
        </div>
        <ManegerModal
          ref={this.manegerModal}
          all={true}
          selectManager={this.selectManager}
        />
        <Drawer
          title='流水'
          visible={billVisible}
          onClose={this.hideBill}
          width={1200}
          footer={
            <div className='w100 row-r'>
              <Button type='primary' onClick={this.hideBill}>关闭</Button>
            </div>
          }
        >
          <div>
            {
              [9, 11].includes(this.role) &&
              <Button onClick={this.toExcel} type='primary'>导出</Button>
            }
            <Table
              columns={columsBill}
              dataSource={billSource}
              rowKey={record => record.id}
              size={'small'}
              pagination={false}
              className='w100'
            />
            <div className='back-color0 w100'>
              <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.pageModal} />
            </div>
          </div>
        </Drawer>
      </div >

    );
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={'搜索模型'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div className={'row-sb'}>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            搜索
          </Button>
          <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            返回
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <KeyOutlined style={{ color: filtered ? '#1890ff' : undefined }} className='fz20 padding-lr5' />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm()
  };
  create = () => {
    const { dataSourceOrder, currentProp } = this.state;
    let currentValue = '';
    let max = 0;
    switch (currentProp) {
      case '正常测量':
        currentValue = 'fin_nor_ms';
        max = 100;
        break;
      case '正常安装':
        currentValue = 'fin_nor_ins';
        max = 100;
        break;
      case '二次测量':
        currentValue = 'fin_sec_ms';
        max = 100;
        break;
      case '二次安装':
        currentValue = 'fin_sec_ins';
        max = 100;
        break;
      case '售后测量':
        currentValue = 'fin_aft_ms';
        max = 100;
        break;
      case '售后安装':
        currentValue = 'fin_aft_ins';
        max = 100;
        break;
      case '充值汇总':
        currentValue = 'recharge';
        max = 50000;
        break;
      case '赠送汇总':
        currentValue = 'present';
        max = 50000;
        break;
      case '消费汇总':
        currentValue = 'pay';
        max = 10000;
        break;
      case '变更汇总':
        currentValue = 'change';
        max = 1000;
        break;
      case '好评汇总':
        currentValue = 'bonus';
        max = 1000;
        break;
      case '退款汇总':
        currentValue = 'refound';
        max = 1000;
        break;
      case '理赔汇总':
        currentValue = 'aftersale';
        max = 2000;
        break;
      case '修正汇总':
        currentValue = 'fix';
        max = 1000;
        break;

    }
    let option = {
      color: [],
      // color: currentProp = '订单汇总' ? ['#07C160', '#f3a75e', '#1890FF'] : ['#07C160'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: dataSourceOrder.map(item => {
          return moment(item.day).format('MM-DD');
        }),
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        max: max
        // max: count
      },
      series: [],
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 25 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    option.color = ['#07C160'];
    option.series = [
      {
        name: '商户每日' + currentProp,
        type: 'bar',
        data: dataSourceOrder.map(item => {
          return currentProp === '消费汇总' ? Math.abs(item[currentValue]) : item[currentValue];
        }),
        barWidth: '30%',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#07C160',
                fontsize: 15
              }
            }
          }
        }
      }
    ];
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)

  }
  getServicerShop = async () => {
    const { searchInfo, } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getShopSummary(searchInfo.servicer, start, end);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let dataSourceShop = res.data.list;
    let summary = {
      orderNum: 0,
      fin_aft_ins: 0,
      fin_aft_ms: 0,
      fin_nor_ins: 0,
      fin_nor_ms: 0,
      fin_sec_ins: 0,
      fin_sec_ms: 0,

      shopPrice: 0,
      shopPrice2: 0,
      workPrice: 0,
      workPrice2: 0,

      recharge: 0,
      pay: 0,
      change: 0,
      bonus: 0,
      present: 0,
      refound: 0,
      aftersale: 0,
      fix: 0,
      aftersaleNum: 0,
      stop_num: 0,
      sameRate: 0,
    };
    dataSourceShop.map(item => {
      summary.orderNum = summary.orderNum + item.fin_aft_ins + item.fin_aft_ms + item.fin_nor_ins + item.fin_nor_ms + item.fin_sec_ins + item.fin_sec_ms + item.stop_num;
      item.orderNum = item.fin_aft_ins + item.fin_aft_ms + item.fin_nor_ins + item.fin_nor_ms + item.fin_sec_ins + item.fin_sec_ms + item.stop_num;
      summary.fin_aft_ins += item.fin_aft_ins;
      summary.fin_aft_ms += item.fin_aft_ms;
      summary.fin_nor_ins += item.fin_nor_ins;
      summary.fin_nor_ms += item.fin_nor_ms;
      summary.fin_sec_ins += item.fin_sec_ins;
      summary.fin_sec_ms += item.fin_sec_ms;

      summary.recharge += item.recharge;
      summary.present += item.present;
      summary.pay += item.pay;
      summary.change += item.change;
      summary.bonus += item.bonus;
      summary.refound += item.refound;
      summary.fix += item.fix;
      summary.aftersale += item.aftersale;
      summary.aftersaleNum += item.aftersaleNum;
      summary.stop_num += item.stop_num;

      summary.shopPrice = summary.shopPrice + item.pay + item.change + item.fix + item.refound;
      summary.shopPrice2 = summary.shopPrice2 + item.pay + item.change + item.fix + item.refound + item.bonus + item.aftersale;
      summary.workPrice = summary.workPrice + item.wincome + item.wchange;
      summary.workPrice2 = summary.workPrice2 + item.wincome + item.wchange + item.wpraise + item.waftersale + item.wrebate;


      let shopPrice = item.pay + item.change + item.fix + item.refound;
      let shopPrice2 = item.pay + item.change + item.fix + item.refound + item.bonus + item.aftersale;
      let workPrice = item.wincome + item.wchange;
      let workPrice2 = item.wincome + item.wchange + item.wpraise + item.waftersale + item.wrebate;
      item.profit = 0;
      item.profit2 = 0;
      if (shopPrice !== 0) {
        item.profit = (-shopPrice - workPrice) / (-shopPrice)
      }
      if (shopPrice2 !== 0) {
        item.profit2 = (-shopPrice2 - workPrice2) / (-shopPrice2)
      }
      //    利润率1=（商家费用汇总-师傅费用汇总）/商家费用汇总
      //    商家费用汇总=商家费用pay-优惠券+商家变更change+商家修正 fix 
      //    师傅费用汇总=师傅费用cost+师傅变更 （师傅利润率一样，跟财务分析中的利润率一样）利润率1理赔和好评不计算在内。

      //   利润率2理赔和好评计算在内。含理赔利润率2=（商家费用汇总-师傅费用汇总）/商家费用汇总     
      // 商家费用汇总=商家费用pay-优惠券+变更change+修正fix+好评bonus+理赔aftersale     
      // 师傅费用汇总=师傅费用+变更+好评-理赔（理赔金额为负值则为减）  
      // 要求客服对于商家和师傅统一类型的费用金额同时操作，只有双方都操作完成后才能被统计进来，
      // 缺一方未完成的都不能进入统计行列。不同时完成操作单取一方数据则会造成利润率不准确。
    })
    summary.sameRate = res.data.total === 0 ? 0 : Number(res.data.trans / res.data.total * 100).toFixed(1) + '%';
    summary.pay = Math.abs(summary.pay);
    let shopInfo = { ...this.state.shopInfo };
    if (dataSourceShop.length > 0) {
      shopInfo.currentShop = dataSourceShop[0].id;
      shopInfo.currentShopName = dataSourceShop[0].name;
    }
    this.setState({
      dataSourceShop,
      summary,
      shopInfo,
    }, () => this.getCurrentShop());
  }
  changeTime = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    let shopInfo = { ...this.state.shopInfo };
    switch (type) {
      case 'start':
        searchInfo.start = e;
        shopInfo.start = e;
        this.setState({
          searchInfo,
          shopInfo,
        }); break;
      case 'end':
        searchInfo.end = e;
        shopInfo.end = e;
        this.setState({
          searchInfo,
          shopInfo,
        }); break;
    }
  }
  selectShop = (e) => {
    let shopInfo = { ...this.state.shopInfo };
    shopInfo.currentShop = e.id;
    shopInfo.currentShopName = e.name;

    this.setState({
      shopInfo,
      currentProp: selectProp[0],
    }, () => this.getCurrentShop())
  }
  showManager = () => {
    this.manegerModal.current.onShow(false);
  }
  selectManager = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    searchInfo.servicer = e[0].id;
    searchInfo.servicerName = e[0].name;
    this.setState({
      searchInfo,
    })
  }
  reset = () => {
    let searchInfo = {
      servicer: 0,
      servicerName: '全部',
      start: moment(new Date()).subtract(31, 'days'),
      end: moment(new Date()),
    }
    this.setState({
      searchInfo,
    }, () => this.getServicerShop())
  }
  quickSelect = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    let shopInfo = { ...this.state.shopInfo };
    if (e === 0) {
      shopInfo.start = searchInfo.start;
      shopInfo.end = searchInfo.end;
    } else {
      let start = moment(new Date()).subtract(e, 'days');
      let end = moment(new Date());
      shopInfo.start = start;
      shopInfo.end = end;
    }
    this.setState({
      shopInfo,
      quickBtn: e,
    }, () => this.getCurrentShop())
  }
  changeProp = (e) => {
    this.setState({
      currentProp: e,
    }, () => this.create())
  }
  getCurrentShop = async () => {
    const { shopInfo } = this.state;
    let start = moment(shopInfo.start).format(dateFormat)
    let end = moment(shopInfo.end).format(dateFormat)
    let res = await getShopOrder(shopInfo.currentShop, start, end);
    if (res.status !== 200) {
      message.error(res, data);
      return;
    }
    let count = {
      '正常测量': 0,
      '正常安装': 0,
      '二次测量': 0,
      '二次安装': 0,
      '售后测量': 0,
      '售后安装': 0,
      '充值汇总': 0,
      '赠送汇总': 0,
      '消费汇总': 0,
      '变更汇总': 0,
      '退款汇总': 0,
      '理赔汇总': 0,
      '修正汇总': 0,
    };
    res.data.list.map(item => {
      count['正常测量'] += item.fin_nor_ms;
      count['正常安装'] += item.fin_nor_ins;
      count['二次测量'] += item.fin_sec_ms;
      count['二次安装'] += item.fin_sec_ins;
      count['售后测量'] += item.fin_aft_ms;
      count['售后安装'] += item.fin_aft_ins;
      count['充值汇总'] += item.recharge;
      count['赠送汇总'] += item.present;
      count['消费汇总'] += item.pay;
      count['变更汇总'] += item.change;
      count['退款汇总'] += item.refound;
      count['理赔汇总'] += item.aftersale;
      count['修正汇总'] += item.fix;
    })
    count['消费汇总'] = Math.abs(count['消费汇总']);
    this.setState({
      dataSourceOrder: res.data.list,
      count,
    }, () => this.create())
  }
  getPropColumns = (e) => {
    if (e === '正常测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '正常测量（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_nor_ms',
          key: 'fin_nor_ms',
          className: 'padding5',
        },
      ];
    } else if (e === '正常安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '正常安装（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_nor_ins',
          key: 'fin_nor_ins',
          className: 'padding5',
        },
      ];
    } else if (e === '二次测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '二次测量（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_sec_ms',
          key: 'fin_sec_ms',
          className: 'padding5',
        },
      ];
    } else if (e === '二次安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '二次安装（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_sec_ins',
          key: 'fin_sec_ins',
          className: 'padding5',
        },
      ];
    } else if (e === '售后测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后测量（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_aft_ms',
          key: 'fin_aft_ms',
          className: 'padding5',
        },
      ];
    } else if (e === '售后安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后安装（单）',
          align: 'center',
          width: 150,
          dataIndex: 'fin_aft_ins',
          key: 'fin_aft_ins',
          className: 'padding5',
        },
      ];
    } else if (e === '充值汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '充值汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'recharge',
          key: 'recharge',
          className: 'padding5',
        },
      ];
    } else if (e === '赠送汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '赠送汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'present',
          key: 'present',
          className: 'padding5',
        },
      ];
    } else if (e === '消费汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '消费汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'pay',
          key: 'pay',
          className: 'padding5',
          render: (text, record, index) => {
            return (Math.abs(record.pay));
          }
        },
      ];
    } else if (e === '变更汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '变更汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'change',
          key: 'change',
          className: 'padding5',
        },
      ];
    } else if (e === '好评汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '好评汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'bonus',
          key: 'bonus',
          className: 'padding5',
        },
      ];
    } else if (e === '退款汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '退款汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'refound',
          key: 'refound',
          className: 'padding5',
        },
      ];
    } else if (e === '理赔汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '理赔汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'aftersale',
          key: 'aftersale',
          className: 'padding5',
        },
      ];
    } else if (e === '修正汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '修正汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'fix',
          key: 'fix',
          className: 'padding5',
        },
      ];
    } else if (e === '售后汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后汇总（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'afterSale_num',
          key: 'afterSale_num',
          className: 'padding5',
        },
      ];
    }
  }

  //展开流水
  onShowBill = (e) => {
    this.setState({
      billType: e,
      billVisible: true,
    }, () => this.onSearchBill())
  }
  onSearchBill = async () => {
    const { searchInfo, billType, skip, take } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getShopBill(billType, false, start, end, skip, take)
    if (res.status === 200) {
      this.setState({
        billSource: res.data,
        billVisible: true,
      })
    }
  }
  toExcel = async () => {
    const { billSource, billType, searchInfo, } = this.state;
    const columsBill = [
      {
        title: '流水号',
        key: 'id',
      },
      {
        title: '订单编号',
        key: 'order',
      },
      {
        title: '订单类型',
        key: 'type',
      },
      {
        title: '商户名称',
        key: 'name',
      },
      {
        title: '费用类型',
        key: 'service_type',
      },
      {
        title: '发生金额',
        key: 'price',
      },
      {
        title: '账户金额',
        key: 'balance',
      },
      {
        title: '结算时间',
        key: 'check_time',
      },
      {
        title: '客户信息',
        key: 'order_info',
      },
      {
        title: '备注信息',
        key: 'content',
      },
    ];
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let allBill = [];
    let data = [];
    let skip = 0;
    let take = 1000;
    let down = true;
    while (down === true) {
      let res = await getShopBill(billType, false, start, end, skip, take)
      if (res.status === 200) {
        allBill = allBill.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }
    data = allBill.map(item => {
      return {
        id: item.id,
        order: item.order,
        type: item.type,
        name: item.name,
        service_type: item.service_type,
        price: item.price,
        balance: item.balance,
        check_time: moment(item.check_time).format('YYYY-MM-DD HH:mm'),
        order_info: item.order_info,
        content: item.content,
      };
    })
    jsToExcel2(columsBill, data, `${billType}汇总`);
  }
  //换页
  pageMaps = ({ skip, take }) => {
    const { navigation } = this.state;
    this.setState({
      skip: skip,
    }, () => this.onSearchBill())
  }
  hideBill = () => {
    this.setState({
      billVisible: false,
      skip: 0,
    })
    this.pageModal.current.returnFirst();
  }
  toExcel2 = () => {
    const { dataSourceShop, } = this.state;
    let colums = [
      {
        title: '商家名称',
        key: 'name',
      },
      {
        title: '订单汇总(单)',
        key: 'orderNum',
      },
      {
        title: '充值汇总(元)',
        key: 'recharge',
      },
      {
        title: '赠送汇总(元)',
        key: 'present',
      },
      {
        title: '消费汇总(元)',
        key: 'pay',
      },
      {
        title: '变更汇总(元)',
        key: 'change',
      },
      {
        title: '好评汇总(元)',
        key: 'bonus',
      },
      {
        title: '退款汇总(元)',
        key: 'refound',
      },
      {
        title: '理赔汇总(元)',
        key: 'aftersale',
      },
      {
        title: '修正汇总(元)',
        key: 'fix',
      },
      {
        title: '利润率1',
        key: 'profit',
      },
      {
        title: '利润率2',
        key: 'profit2',
      },
      {
        title: '正常测量(单)',
        key: 'fin_nor_ms',
      },
      {
        title: '正常安装(单)',
        key: 'fin_nor_ins',
      },
      {
        title: '二次测量(单)',
        key: 'fin_sec_ms',
      },
      {
        title: '二次安装(单)',
        key: 'fin_sec_ins',
      },
      {
        title: '售后测量(单)',
        key: 'fin_aft_ms',
      },
      {
        title: '售后安装(单)',
        key: 'fin_aft_ins',
      },
      {
        title: '终止订单(单)',
        key: 'stop_num',
      },

    ];
    let data = dataSourceShop.map(item => {
      return {
        name: item.name,
        orderNum: item.orderNum,
        recharge: item.recharge,
        present: item.present,
        pay: item.pay,
        change: item.change,
        bonus: item.bonus,
        refound: item.refound,
        aftersale: item.aftersale,
        fix: item.fix,
        profit: Number(item.profit * 100).toFixed(1) + '%',
        profit2: Number(item.profit2 * 100).toFixed(1) + '%',
        fin_nor_ms: item.fin_nor_ms,
        fin_nor_ins: item.fin_nor_ins,
        fin_sec_ms: item.fin_sec_ms,
        fin_sec_ins: item.fin_sec_ins,
        fin_aft_ms: item.fin_aft_ms,
        fin_aft_ins: item.fin_aft_ins,
        stop_num: item.stop_num,
      };
    })
    jsToExcel2(colums, data, '商户订单分析');
  }
  toExcel3 = () => {
    const { currentProp, dataSourceOrder, shopInfo } = this.state;
    let prop = '';
    switch (currentProp) {
      case '正常测量': prop = 'fin_nor_ms'; break;
      case '正常安装': prop = 'fin_nor_ins'; break;
      case '二次测量': prop = 'fin_sec_ms'; break;
      case '二次安装': prop = 'fin_sec_ins'; break;
      case '售后测量': prop = 'fin_aft_ms'; break;
      case '售后安装': prop = 'fin_aft_ins'; break;
    }
    let colums = this.getPropColumns(currentProp)
    let data = dataSourceOrder.map(item => {
      return {
        date: moment(item.day).format(dateFormat),
        [prop]: item[prop]
      };
    })
    correctExcel(colums, data, `${shopInfo.currentShopName}${currentProp}`);
  }
}

export default Index;


// 1月10号
// 单日订单分析   商户订单分析
// 商家修正fix   ===  变更change
// 商家好评bonus   ===  修正fix
// 商家退款bonus   ===  理赔fix
