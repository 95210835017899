import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, DatePicker, Checkbox } from 'antd';
import * as echarts from 'echarts';
import { getAreaKpi } from '../../services/analysisCenter';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateForm = 'YYYY-MM-DD';
const today = moment(new Date()).format(dateForm);
const monthAgo = moment(new Date()).subtract(31, 'days').format(dateForm);

class AreaCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: monthAgo,
      end: today,
      dataSource: [],
    };
    this.columns = [
      {
        title: '客服',
        align: 'center',
        width: 100,
        dataIndex: 'name',
      },
      {
        title: '订单量',
        align: 'center',
        width: 100,
        dataIndex: 'orderNum',
      },
      {
        title: '3小时预约率',
        align: 'center',
        width: 100,
        dataIndex: 'ap3HourRate',
        render: (text) => {
          return (<div>{text ? Number(text * 100).toFixed(1) + '%' : text}</div>);
        }
      },
      {
        title: '好评数量',
        align: 'center',
        width: 100,
        dataIndex: 'bonusNum',
      },
      {
        title: '利润',
        align: 'center',
        width: 100,
        dataIndex: 'profit',
      },
      {
        title: '利润率',
        align: 'center',
        width: 100,
        dataIndex: 'profitRate',
        render: (text) => {
          return (<div>{text ? Number(text * 100).toFixed(1) + '%' : text}</div>);
        }
      },
      {
        title: '理赔金额',
        align: 'center',
        width: 100,
        dataIndex: 'aftersalePrice',
      },
      {
        title: '理赔系数',
        align: 'center',
        width: 100,
        dataIndex: 'aftersalePriceRate',
        render: (text) => {
          return (<div>{text ? Number(text * 100).toFixed(1) + '%' : text}</div>);
        }
      },
    ];
  }
  componentDidMount() {
    this.getAreaKpi()
  }
  render() {
    const { start, end, dataSource } = this.state;
    return (
      <div className='col-t w100'>
        <div className='row-l w100 bar'>
          <div>时间范围：</div>
          <DatePicker value={moment(start)} onChange={this.chagneTime.bind(this, 'start')} allowClear={false} locale={locale} />
          <DatePicker value={moment(end)} onChange={this.chagneTime.bind(this, 'end')} allowClear={false} locale={locale} />
          <Button onClick={this.getAreaKpi} type='primary'>搜索</Button>
        </div>
        <div className='bar w100'>
          <Table
            columns={this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => Math.random()}
            bordered
            className='w100'
            pagination={false}
          />
        </div>
        <div className='bar w100'>
          <div className={`${styles['count']} h480`} id='main'></div>
        </div>
      </div>
    );
  }
  chagneTime = (type, e) => {
    if (type === 'start') {
      this.setState({
        start: moment(e).format(dateForm)
      })
    } else {
      this.setState({
        end: moment(e).format(dateForm)
      })
    }
  }
  getAreaKpi = async () => {
    const { start, end } = this.state;
    let res = await getAreaKpi(start, end);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data
      })
      this.sort2(res.data);
    }
  }
  create = () => {
    const { dataSource } = this.state;
    let colors = ['#5470C6', '#91CC75', '#EE6666'];
    let option = {
      colors: colors,
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'cross'
        },
      },

      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      legend: {
        data: ['订单量', '3小时预约率', '好评数量', '利润', '利润率', '理赔金额', '理赔系数'],
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            show: true,
            interval: 0
          },
          data: dataSource.map(item => item.name)
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '数量/金额',
          // barWidth: '20%',
          position: 'left',
          // alignTicks: true,
          // axisLine: {
          //   show: true,
          //   lineStyle: {
          //     color: colors[0]
          //   }
          // },
        },
        {
          type: 'value',
          name: '比率/系数',
          position: 'right',
          // alignTicks: true,
          // axisLine: {
          //   show: true,
          //   lineStyle: {
          //     color: colors[2]
          //   }
          // },
          // axisLabel: {
          //   formatter: '{value} °C'
          // }
        }
      ],
      series: [
        {
          name: '订单量',
          type: 'bar',
          yAxisIndex: 0,
          data: dataSource.map(item => item.orderNum)
        },
        {
          name: '3小时预约率',
          type: 'line',
          yAxisIndex: 1,
          data: dataSource.map(item => item.ap3HourRate)
        },
        {
          name: '好评数量',
          // type: 'line',
          type: 'bar',
          yAxisIndex: 0,
          data: dataSource.map(item => item.bonusNum)
        },
        {
          name: '利润',
          type: 'bar',
          yAxisIndex: 0,
          data: dataSource.map(item => item.profit)
        },
        {
          name: '利润率',
          type: 'line',
          yAxisIndex: 1,
          data: dataSource.map(item => item.profitRate)
        },
        {
          name: '理赔金额',
          type: 'bar',
          yAxisIndex: 0,
          data: dataSource.map(item => item.aftersalePrice)
        },
        {
          name: '理赔系数',
          type: 'line',
          yAxisIndex: 1,
          data: dataSource.map(item => item.aftersalePriceRate)
        },
      ],
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  sort2 = (dataSource) => {
    let data = [];
    let data2 = [];
    let keys = {
      '后台一区': 6,
      '后台二区': 7,
      '后台三区': 8,
      '后台四区': 9,
      '后台五区': 10,
      '后台六区': 11,
      '后台七区': 12,
      '后台八区': 13,
      '后台九区': 14,
      '后台十区': 15,
      '后台十一': 16,
      '后台十二': 17,
    };
    dataSource.map(item => {
      if (item.name.includes('后台一组组长')) {
        data.unshift(item);
      } else if (item.name.includes('后台二组组长')) {
        data.unshift(item);
      }
      // else if (item.name.includes('后台优化专员')) {
      //   data.push(item);
      // }
      if (keys[item.name.slice(0, 4)]) {
        data2[keys[item.name.slice(0, 4)]] = item;
      }
    })
    data = data.concat(data2)
    data = data.filter(r => r);
    this.setState({
      dataSource: data
    }, () => this.create());
  }
}
export default AreaCompare;