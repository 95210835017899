import React, { Component } from 'react';
import styles from './index.module.css';
import { Table, Select, Modal, Button, DatePicker, Checkbox } from 'antd';
import ManegerModal from '../../components/managerModal/index.jsx';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { getDepartment_Data } from '../../config/storage.js';
import { putPlatOrderList, getServicerList } from '../../services/analysisCenter.js';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const provincesList = ['北京市', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区', '黑龙江省', '辽宁省', '吉林省', '河北省', '河南省', '湖北省', '湖南省', '山东省', '山西省', '陕西省', '安徽省', '浙江省', '江苏省', '福建省', '广东省', '海南省', '四川省', '云南省', '贵州省', '青海省', '甘肃省', '江西省', '台湾省', '内蒙古自治区', '宁夏回族自治区', '新疆维吾尔自治区', '西藏自治区', '广西壮族自治区',];
const today = moment(new Date());
const monthAgo = moment(new Date()).subtract(31, 'days');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerList: [],
      searchInfo: {
        typeVisible: false,
        type: '商家客服',
        servicer: 0,
        servicerName: '',
        start: monthAgo,
        end: today,
        provinces: [],
      },
      countRate: {
        bonus_num: 0,
        hour3_num: 0,
        self_num: 0,
        number: 0,
        cre_aft_ins: 0,
        cre_aft_ms: 0,
        cre_nor_ins: 0,
        cre_nor_ms: 0,
        cre_sec_ins: 0,
        cre_sec_ms: 0,
        cre_stop_num: 0,
      },
      isRegion: false,
      quickBtn: 0,
      source: [],
      provinces: [],
      newProvince: '',
      modalShow: false,
      kpiData: [],
      kpiInfo: {
        start: monthAgo,
        end: today,
      },
    };
    this.manegerModal = React.createRef();//选择客服
  }
  componentDidMount() {
    this.setState({
      provinces: provincesList,
      newProvince: provincesList[0],
    });
    this.reset()
    this.getServicer()
  }
  render() {
    const { option, source, searchInfo, servicerList, quickBtn, countRate,
      modalShow, newProvince, provinces, isRegion } = this.state;
    let colums = [
      {
        title: '下单时间',
        align: 'center',
        width: 200,
        dataIndex: 'date',
        key: 'date',
        render: (text, record, index) => {
          return <div>{moment(record.date).format(dateFormat)}</div>;
        }
      },
      {
        title: '订单汇总(单)',
        align: 'center',
        width: 200,
        dataIndex: 'orderNum',
        key: 'orderNum',
      },
      {
        title: '正常测量(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_nor_ms',
        key: 'cre_nor_ms',
      },
      {
        title: '正常安装(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_nor_ins',
        key: 'cre_nor_ins',
      },
      {
        title: '二次测量(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_sec_ms',
        key: 'cre_sec_ms',
      },
      {
        title: '二次安装(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_sec_ins',
        key: 'cre_sec_ins',
      },
      {
        title: '售后测量(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_aft_ms',
        key: 'cre_aft_ms',
      },
      {
        title: '售后安装(单)',
        align: 'center',
        width: 200,
        dataIndex: 'cre_aft_ins',
        key: 'cre_aft_ins',
      },
    ];
    if (isRegion) {
      colums = colums.concat([
        {
          title: '3小时内预约量',
          align: 'center',
          width: 200,
          dataIndex: 'date',
          key: 'date',
          render: (text, record, index) => {
            return <div>{record.hour3_num}</div>;
          }
        },
        {
          title: '好评数量',
          align: 'center',
          width: 200,
          dataIndex: 'date',
          key: 'date',
          render: (text, record, index) => {
            return <div>{record.bonus_num ? record.bonus_num : 0}</div>;
          }
        },
        {
          title: '自助上传数量',
          align: 'center',
          width: 200,
          dataIndex: 'date',
          key: 'date',
          render: (text, record, index) => {
            return <div>{record.self_num ? record.self_num : 0}</div>;
          }
        },
      ]);
    }
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <Checkbox
                className='margin-r10'
                checked={searchInfo.typeVisible}
                onChange={this.changeSummary.bind(this, 'typeVisible')}
              />
              <Select value={searchInfo.type}
                onChange={this.changeSummary.bind(this, 'type')}
                className='w130'
              >
                <Select.Option value='商家客服' key='shop'>商家客服</Select.Option>
                <Select.Option value='区域客服' key='area'>区域客服</Select.Option>
              </Select>
              <div className='w166 margin-l20 bord3 h32 tc none-select'>
                {searchInfo.servicerName}
              </div>
              <Button disabled={!searchInfo.typeVisible} onClick={this.showManager} type='primary'>选择</Button>
              {
                searchInfo.type === '区域客服' &&
                <div className='row-l'>
                  <div className='margin-l20'>负责区域：</div>
                  <div className={`${styles['provinces-box']} row-sb`}>
                    <div className={`${styles['provinces']} row-w`}>
                      {
                        searchInfo.provinces.map((item, index) => {
                          return (
                            <div className={`${styles['btn']} row-sb`} key={index}>
                              <div className='oneline'>{item}</div>
                              {/* <div onClick={this.delProvince.bind(this, index)} className='cursor-pointer padding-lr5'>X</div> */}
                            </div>
                          );
                        })
                      }
                    </div>
                    <div onClick={this.showModal} className={`${styles['add']} color6 fz15`}>添加</div>
                  </div>
                </div>
              }
              <div className={`${styles['tit']} tr`}>结算时间：</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} allowClear={false} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} allowClear={false} />
              <Button onClick={this.search} type='primary' className='margin-l20 margin-r10'>搜索</Button>
            </div>
            <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <div className='row-sb vertical-lt w100'>
            <div className='row-l'>
              <div className='fz18 bold'>平台每日订单量</div>
              <div className={`${styles['selectDate']} color7`}>快速选择：</div>
              <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
              <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
              <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
            </div>
            <div className='col-t vertical-rb'>
              <div className='row-r'>
                <div className='color7'>汇总：</div>
                <div className={`${styles['summary-finance']} row`}>
                  <div className='bold'>{countRate.number}</div>
                  <div className='color5'> （订单）</div>
                  <div>正常测量：{countRate.cre_nor_ms}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>正常安装：{countRate.cre_nor_ins}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>二次测量：{countRate.cre_sec_ms}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>二次安装：{countRate.cre_sec_ins}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>售后测量：{countRate.cre_aft_ms}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>售后安装：{countRate.cre_aft_ins}</div>
                  <div className='color5 margin-lr5'>|</div>
                  <div>终止订单：{countRate.cre_stop_num}</div>
                </div>
              </div>
              {
                isRegion &&
                <div className='row-r margin-t10'>
                  <div className='color7'>3小时内预约量：</div>
                  <div className={`${styles['summary']} row margin-r10`}>
                    <div className='bold'>{countRate.hour3_num} </div>
                  </div>
                  <div className='color7'>3小时内预约率：</div>
                  <div className={`${styles['summary']} row w155`}>
                    <div className='bold'>
                      {countRate.number && countRate.hour3_num / countRate.number ? (countRate.hour3_num / countRate.number * 100).toFixed(1) + '%' : 0}
                    </div>
                  </div>
                </div>
              }
              {
                isRegion &&
                <div className='row-r margin-t10'>
                  <div className='color7'>好评量：</div>
                  <div className={`${styles['summary']} row margin-r10`}>
                    <div className='bold'>{countRate.bonus_num}</div>
                    <div className='color5'></div>
                  </div>
                  <div className='color7'>好评率：</div>
                  <div className={`${styles['summary']} row`}>
                    <div className='bold'>{countRate.number && countRate.bonus_num / countRate.number ? (countRate.bonus_num / countRate.number * 100).toFixed(1) + '%' : 0}</div>
                    <div className='color5'></div>
                  </div>
                </div>
              }
              {
                isRegion &&
                <div className='row-r margin-t10'>
                  <div className='color7'>师傅自助上传量：</div>
                  <div className={`${styles['summary']} row margin-r10`}>
                    <div className='bold'>{countRate.self_num}</div>
                    <div className='color5'></div>
                  </div>
                  <div className='color7'>师傅自助上传率：</div>
                  <div className={`${styles['summary']} row`}>
                    <div className='bold'>{countRate.number && countRate.self_num / countRate.number ? (countRate.self_num / countRate.number * 100).toFixed(1) + '%' : 0}</div>
                    <div className='color5'></div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div>
            <div className={`${styles['count']}`} id='main'></div>
          </div>
          <div className='w100 row margin-t20'>
            <div className={styles['box3']} />
            <div>订单量</div>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <Table
            columns={colums}
            dataSource={source}
            size={'small'}
            rowKey={record => record.date}
            bordered
          />
        </div>
        <ManegerModal
          ref={this.manegerModal}
          selectManager={this.selectManager}
        />
        <Modal
          title='选择区域'
          width={450}
          visible={modalShow}
          cancelText='取消'
          okText='确定'
          onCancel={this.hideModal}
          onOk={this.changeSummary.bind(this, 'newProvince')}
        >
          <div className='row-l'>
            <div>新增区域：</div>
            <Select value={newProvince}
              onChange={this.changeSummary.bind(this, 'province')}
              className='w166'
            >
              {
                provinces.map((item, index) => {
                  return <Select.Option value={item} key={index}>{item}</Select.Option>;
                })
              }
            </Select>
          </div>
        </Modal>
      </div >
    );

  }
  create = () => {
    const { source, count } = this.state;
    let option = {
      color: ['#07C160', '#f3a75e', '#1890FF'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: source.map(item => {
          return moment(item.date).format('MM-DD');
        }),
        axisTick: {
          alignWithLabel: true
        },
      },
      yAxis: {
        type: 'value',
        max: 2000
        // max: count
      },
      series: [
        {
          name: '平台每日订单量',
          type: 'bar',
          data: source.map(item => {
            return item.number
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日正常测量',
          type: 'bar',
          data: source.map(item => {
            return item.cre_nor_ms
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#f3a75e',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日正常安装',
          type: 'bar',
          data: source.map(item => {
            return item.cre_nor_ins
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#1890FF',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日二次测量',
          type: 'bar',
          data: source.map(item => {
            return item.cre_sec_ms
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#f3a75e',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日二次安装',
          type: 'bar',
          data: source.map(item => {
            return item.cre_sec_ins
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#1890FF',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日售后测量',
          type: 'bar',
          data: source.map(item => {
            return item.cre_aft_ms
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#f3a75e',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          name: '平台每日售后安装',
          type: 'bar',
          data: source.map(item => {
            return item.cre_aft_ins
          }),
          barWidth: '10%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#1890FF',
                  fontsize: 16
                }
              }
            }
          }
        },
      ],
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 31 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  getServicer = async () => {
    let res = await getServicerList()
    if (res.status === 200) {
      this.setState({
        servicerList: res.data
      });
    }
  }
  getData = async () => {
    const { searchInfo, } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await putPlatOrderList(start, end, searchInfo.typeVisible, searchInfo.type, searchInfo.servicer, searchInfo.provinces);
    if (res.status === 200) {
      let countRate = {
        bonus_num: 0,
        hour3_num: 0,
        self_num: 0,
        number: 0,
        cre_aft_ins: 0,
        cre_aft_ms: 0,
        cre_nor_ins: 0,
        cre_nor_ms: 0,
        cre_sec_ins: 0,
        cre_sec_ms: 0,
        cre_stop_num: 0,
      };
      let isRegion = searchInfo.typeVisible && searchInfo.type === '区域客服' ? true : false;
      res.data.map(item => {
        item.orderNum = item.cre_aft_ins + item.cre_aft_ms + item.cre_nor_ins + item.cre_nor_ms + item.cre_sec_ins + item.cre_sec_ms;
        item.number = item.cre_aft_ins + item.cre_aft_ms + item.cre_nor_ins + item.cre_nor_ms + item.cre_sec_ins + item.cre_sec_ms;
        countRate.number = countRate.number + item.cre_aft_ins + item.cre_aft_ms + item.cre_nor_ins + item.cre_nor_ms + item.cre_sec_ins + item.cre_sec_ms;
        countRate.cre_aft_ins += item.cre_aft_ins;
        countRate.cre_aft_ms += item.cre_aft_ms;
        countRate.cre_nor_ins += item.cre_nor_ins;
        countRate.cre_nor_ms += item.cre_nor_ms;
        countRate.cre_sec_ins += item.cre_sec_ins;
        countRate.cre_sec_ms += item.cre_sec_ms;
        countRate.cre_stop_num += item.cre_stop_num;
        if (searchInfo.typeVisible && searchInfo.type === '区域客服') {
          countRate.bonus_num += item.bonus_num;
          countRate.hour3_num += item.hour3_num;
          countRate.self_num += item.self_num;
        }

      })
      this.setState({
        source: res.data,
        countRate, isRegion,
      }, () => this.create())
    }
  }
  changeSummary = (type, e) => {
    const { servicerList, newProvince } = this.state;
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'typeVisible':
        searchInfo.typeVisible = e.target.checked;
        this.setState({
          searchInfo
        }); break;
      case 'type':
        searchInfo.type = e;
        this.setState({
          searchInfo
        }); break;
      case 'start':
        searchInfo.start = e;
        this.setState({
          searchInfo
        }); break;
      case 'end':
        searchInfo.end = e;
        this.setState({
          searchInfo
        }); break;
      case 'servicer':
        searchInfo.servicer = servicerList.filter(r => r.id === e)[0].name;
        searchInfo.provinces = servicerList.filter(r => r.id === e)[0].areas.map(item => { return item.area; });
        this.setState({
          searchInfo
        }); break;
      case 'province':
        this.setState({
          newProvince: e
        }); break;
      case 'newProvince':
        searchInfo.provinces.push(newProvince);
        this.setState({
          searchInfo,
          modalShow: false
        }); break;
    }
  }
  search = () => {
    this.setState({
      quickBtn: 0
    }, () => this.getData())
  }
  quickSelect = (e) => {
    let searchInfo = {
      ...this.state.searchInfo,
      end: moment(new Date()),
      start: moment(new Date()).subtract(e, 'days'),
    }
    this.setState({
      quickBtn: e,
      searchInfo,
    }, () => this.getData())
  }
  reset = () => {
    let department_data = getDepartment_Data();
    let searchInfo = {
      typeVisible: false,
      type: '商家客服',
      start: monthAgo,
      end: today,
      servicer: department_data.id,
      servicerName: department_data.name,
      provinces: department_data.areas,
    };
    this.setState({
      searchInfo,
      quickBtn: 0,
    }, () => this.getData());
  }
  showManager = () => {
    this.manegerModal.current.onShow(false);
  }
  selectManager = (e) => {
    const { servicerList } = this.state;
    let searchInfo = { ...this.state.searchInfo };
    let manager = e[0];
    searchInfo.servicer = manager.id;
    searchInfo.servicerName = manager.name;
    searchInfo.provinces = servicerList.filter(r => r.id === manager.id)[0].areas.map(item => { return item.area; });
    this.setState({
      searchInfo
    });
  }

  showModal = () => {
    this.setState({
      modalShow: true
    });
  }
  hideModal = () => {
    this.setState({
      modalShow: false
    });
  }
}

export default Index;