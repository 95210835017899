import request from '../utils/request';
//师傅列表
export async function postMaster(data) {
  return await request('platbiz/workerlist', 'post', data);
}
// export async function getMaster(manager, check, sign, enable, status, info, province, city, district, worktype, ensured, skip, take, sort) {
//   return await request(`platbiz/workerlist?manager=${manager}&check=${check}&sign=${sign}&enable=${enable}&status=${status}&info=${info}&province=${province}&city=${city}&district=${district}&worktype=${worktype}&ensured=${ensured}&skip=${skip}&take=${take}&sort=${sort}`, 'get');
// }

//获取师傅信息
export async function masterInfo(workerid) {
  return await request(`platbiz/workerinfo?workerid=${workerid}`, 'get');
}
//删除师傅
export async function delMaster(workerid) {
  return await request(`platbiz/delworker?workerid=${workerid}`, 'delete');
}

//修改师傅信息
export async function editMasterInfo(workerid, body) {
  return await request(`platbiz/mdyworkerbase?workerid=${workerid}`, 'put', body);
}
//新增师傅
export async function addMasterInfo(body) {
  return await request(`platbiz/addworker`, 'post', body);
}

//修改师傅服务信息
export async function editMasterService(workerid, body) {
  return await request(`platbiz/mdyworkerservice?workerid=${workerid}`, 'put', body);
}

//修改师傅服务账户
export async function editMasterAccount(workerid, body) {
  return await request(`platbiz/mdyworkeraccount?workerid=${workerid}`, 'put', body);
}

//获取财务概览
export async function getMasterAmount(workerid) {
  return await request(`platbiz/workerfinancebase?workerid=${workerid}`, 'get');
}

//获取师傅流水
export async function getMasterBill(workerid, info, type, start, end, skip, take) {
  return await request(`platbiz/workerfinancelist?workerid=${workerid}&info=${info}&type=${type}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
//获取单个师傅提现列表
export async function getWithdrawalList(workerid, state, skip, take) {
  return await request(`platbiz/workerwithdrawlist?workerid=${workerid}&state=${state}&skip=${skip}&take=${take}`, 'get');
}

//调整师傅余额
export async function editMasterBalance(worker, price, ramark, orderid) {
  return await request(`platbiz/adjworkerfin?worker=${worker}&price=${price}&remark=${ramark}&orderid=${orderid}`, 'put');
}
//处理师傅提现
export async function editMasterWithdrawal(id, state, ramark) {
  return await request(`platbiz/putwithdraw?id=${id}&state=${state}&remark=${ramark}`, 'put');
}
//新增师傅提现
export async function addMasterWithdrawal(worker, body) {
  return await request(`platbiz/addwidthdraw?worker=${worker}`, 'post', body);
}

//获取师傅跟踪记录
export async function getMasterFollow(workerid, type, skip, take) {
  return await request(`platbiz/workervisitlist?workerid=${workerid}&type=${type}&skip=${skip}&take=${take}`, 'get');
}
//新增师傅跟踪记录
export async function addMasterFollow(orderid, workerid, type, content) {
  return await request('platbiz/addworkervisit', 'post', { orderid, workerid, type, content });
}

//获取师傅操作记录
export async function getMasterOperate(workerid, skip, take) {
  return await request(`platbiz/workerchangelist?workerid=${workerid}&skip=${skip}&take=${take}`, 'get');
}

//获取师傅余额汇总
export async function getMasterCount() {
  return await request(`platbiz/workertblance`, 'get');
}
//获取师傅服务数据
export async function getMasterKpi(workerid, start, end) {
  return await request(`platbiz/workerkpi?workerid=${workerid}&start=${start}&end=${end}`, 'get');
}
//订单转化
export async function getMasterTransfer(worker, state, type, start, end, skip, take) {
  return await request(`platbiz/transorderworker?worker=${worker}&state=${state}&type=${type}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}


//获取师傅余额汇总
export async function getMasterAreaRank(province, city, district) {
  return await request(`platbiz/workerAreaRank?province=${province}&city=${city}&district=${district}`, 'get');
}
//获取师傅假期
export async function putMasterHoliday(id, i) {
  return await request(`workerbiz/holiday?id=${id}&i=${i}`, 'put');
}
//重置密码
export async function resetPwd(id, type) {
  return await request(`platbiz/resetpwd?id=${id}&type=${type}`, 'put');
}
