import React, { Component } from 'react';
import { Button, Modal, Radio, Table, Input, message } from 'antd';
import PageTurning from '../../../components/pageTurning';
import { getAppeal, postAppeal } from '../../../services/orderCore.js';
import moment from 'moment';

const types = ['回访', '投诉', '培训', '评价'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      record: {},
      modal: false,
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
      },
      backInfo: {
        normal: '',
        clear: '',
        box: '',
        site: '',
        space: '',
        outside: '',
        detail: '',
        round: '',
        sign: '',
        talk: '',
        late: '',
        attitude: '',
        mark: '',
      },
    }
    this.pageModal = React.createRef();

  }
  render() {
    const { data, modal, addModal, backInfo, } = this.state;
    const columsFollow = [
      {
        title: '编号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: '客服',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 120,
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 100,
      },
      {
        title: '时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: 150,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
        width: 300,
      },
      {
        title: '关联订单',
        dataIndex: 'order',
        key: 'order',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (<div>{record.type === '培训' ? '/' : record.order}</div>)
        }
      },
      {
        title: '关联客户信息',
        dataIndex: 'info',
        key: 'order',
        align: 'center',
        width: 300,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>
              {
                record.order ?
                  <div>
                    <div>{record.cname}-{record.phone}</div>
                    <div>{record.province}{record.city}{record.district}{record.address}</div>
                  </div>
                  : <div></div>
              }
            </div>
          );
        }
      },
    ];
    return (
      <Modal
        title='关联记录'
        visible={modal}
        width={1000}
        onCancel={this.hideModal}
        footer={null}
      >
        <div className='w100 margin-t10 back-color0 '>
          <div className='row-l margin-b10'>
            <Button onClick={this.newRecord} type='primary'>新增记录</Button>
          </div>
          <Table
            rowKey={record => record.id}
            dataSource={data}
            columns={columsFollow}
            bordered
          />
        </div>
        <Modal
          title='新增记录'
          visible={addModal.visible}
          width={600}
          onCancel={this.onCancel}
          footer={
            <div>
              <Button onClick={this.onCancel} type='ghost'>取消</Button>
              <Button onClick={this.addRecord} type='primary'>确认</Button>
            </div>
          }
        >
          <div className='row-l'>
            <div className='w60'>类型：</div>
            <Radio.Group value={addModal.type} onChange={this.changeType}>
              {
                types.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          {
            addModal.type !== '回访' &&
            <div className='row-l margin-t10'>
              <div className='w60'>说明：</div>
              <Input value={addModal.remark} className='w300' onChange={this.changeRemark} />
            </div>
          }
          {
            addModal.type === '回访' &&
            <div className='col-t vertical-lt'>
              <div className='row-l bottomLine'>
                <div className='w130'>小程序是否规范:</div>
                <Radio.Group value={backInfo.normal} onChange={this.changeBack.bind(this, 'normal')} className='w400'>
                  {
                    ['填写时间不准确，需沟通规范', '准确填写，且按时签到', '准确填写，未签到'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>宽度高度描述是否清晰:</div>
                <Radio.Group value={backInfo.clear} onChange={this.changeBack.bind(this, 'clear')} className='w400'>
                  {
                    ['无描述，需沟通规范', '有描述，不清晰', '有描述，且清晰'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>有窗帘盒，盒宽盒高有无测量:</div>
                <Radio.Group value={backInfo.box} onChange={this.changeBack.bind(this, 'box')} className='w400'>
                  {
                    ['有测量', '无测量，需沟通规范', '无窗帘盒'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>安装位置:</div>
                <Radio.Group value={backInfo.site} onChange={this.changeBack.bind(this, 'site')} className='w400'>
                  {
                    ['有测量', '无测量，需沟通规范'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>是否测量影响窗帘使用的内开窗间距:</div>
                <Radio.Group value={backInfo.space} onChange={this.changeBack.bind(this, 'space')} className='w400'>
                  {
                    ['有测量', '无测量，需沟通规范', '无影响'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>飘窗内外方案:</div>
                <Radio.Group value={backInfo.outside} onChange={this.changeBack.bind(this, 'outside')} className='w400'>
                  {
                    ['有测量', '无测量，需沟通规范', '无飘窗'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>影响安装/使用的细节备注:</div>
                <Radio.Group value={backInfo.detail} onChange={this.changeBack.bind(this, 'detail')} className='w400'>
                  {
                    ['有测量', '无测量，需沟通规范', '无影响因素'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>房间照片是否全景/清晰:</div>
                <Radio.Group value={backInfo.round} onChange={this.changeBack.bind(this, 'round')} className='w400'>
                  {
                    ['有', '无，需沟通规范'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>手稿签字:</div>
                <Radio.Group value={backInfo.sign} onChange={this.changeBack.bind(this, 'sign')} className='w400'>
                  {
                    ['有手稿，有签字', '有手稿，无签字。需沟通规范'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>沟通内容:</div>
                <Input.TextArea value={backInfo.talk} onChange={this.changeBack.bind(this, 'talk')} className='w400' rows={2} />
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>是否迟到:</div>
                <Radio.Group value={backInfo.late} onChange={this.changeBack.bind(this, 'late')} className='w400'>
                  {
                    ['是', '否'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>服务态度:</div>
                <Radio.Group value={backInfo.attitude} onChange={this.changeBack.bind(this, 'attitude')} className='w400'>
                  {
                    ['好', '不好'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt bottomLine'>
                <div className='w130'>推销线下产品:</div>
                <Radio.Group value={backInfo.mark} onChange={this.changeBack.bind(this, 'mark')} className='w400'>
                  {
                    ['是', '否'].map((item, index) => {
                      return (
                        <Radio value={item} key={index}>{item}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
            </div>
          }
        </Modal>
      </Modal>
    );
  }
  onShowModal = (e) => {
    this.setState({
      record: e,
      modal: true,
    }, () => this.getcomplaints())
  }
  getcomplaints = async () => {
    const { record, } = this.state;
    let res = await getAppeal(record.id, record.workerId,);
    if (res.status === 200) {
      this.setState({
        data: res.data
      })
    }
  }
  hideModal = () => {
    this.setState({
      modal: false,
      data: [],
      skip: 0,
    })
  }
  changeBack = (type, e) => {
    let backInfo = { ...this.state.backInfo };
    backInfo[type] = e.target.value;
    this.setState({
      backInfo
    })
  }
  newRecord = () => {
    let addModal = { ...this.state.addModal };
    addModal.visible = true;
    this.setState({
      addModal,
    });
  }
  changeType = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.type = e.target.value;
    this.setState({
      addModal,
    });
  }
  changeRemark = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.remark = e.target.value;
    this.setState({
      addModal,
    });
  }


  addRecord = async () => {
    const { addModal, record, backInfo } = this.state;
    let content = addModal.remark;
    if (addModal.type === '回访') {
      // if (backInfo.normal === '' || backInfo.clear === '' || backInfo.box === '' || backInfo.site === '' ||
      //   backInfo.space === '' || backInfo.outside === '' || backInfo.detail === '' || backInfo.round === '' ||
      //   backInfo.sign === '' || backInfo.late === '' || backInfo.attitude === '' || backInfo.mark === '') {
      //   message.warn('带*号的未选择');
      //   return;
      // } else {
      content = `${backInfo.normal ? '小程序是否规范操作:' + backInfo.normal + ';' : ''}
        ${backInfo.clear ? '宽度高度描述是否清晰:' + backInfo.clear + ';' : ''}
        ${backInfo.box ? '有窗帘盒，盒宽盒高有无测量:' + backInfo.box + ';' : ''}
        ${backInfo.site ? '安装位置:' + backInfo.site + ';' : ''}
        ${backInfo.space ? '是否测量影响窗帘使用的内开窗间距:' + backInfo.space + ';' : ''}
        ${backInfo.outside ? '飘窗内外方案:' + backInfo.outside + ';' : ''}
        ${backInfo.detail ? '影响安装/使用的细节备注:' + backInfo.detail + ';' : ''}
        ${backInfo.round ? '房间照片是否全景/清晰:' + backInfo.round + ';' : ''}
        ${backInfo.sign ? '手稿签字:' + backInfo.sign + ';' : ''}
        ${backInfo.talk ? '沟通内容:' + backInfo.talk + ';' : ''}
        ${backInfo.late ? '是否迟到:' + backInfo.late + ';' : ''}
        ${backInfo.attitude ? '服务态度:' + backInfo.attitude + ';' : ''}
        ${backInfo.mark ? '推销线下产品:' + backInfo.mark + ';' : ''};`;
      // }
    }
    let res = await postAppeal(record.id, record.workerId, addModal.type, content);
    if (res.status === 200) {
      this.getcomplaints();
      this.onCancel();
    }
  }
  onCancel = () => {
    this.setState({
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
      },
      backInfo: {
        normal: '',
        clear: '',
        box: '',
        site: '',
        space: '',
        outside: '',
        detail: '',
        round: '',
        sign: '',
        talk: '',
        late: '',
        attitude: '',
        mark: '',
      },
    });
  }
}

export default Index;