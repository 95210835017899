import React, { Component } from 'react';
import { Modal, Drawer, Select, Table, message, Button, Popconfirm, Checkbox, Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import bluemark from '../../../resource/png/all-worker.png';
import redmark from '../../../resource/png/all-customer.png';
import Regin from '../../../components/regioRegister';
import { getAllotMaster, getDistance } from '../../../services/orderCore';
import { toTitude } from '../../../services/general';
import { Map, Marker, InfoWindow } from 'react-amap';

const rankList = ['全部', '默认', 'A', 'B', 'C', 'D'];
class MapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      provinces: ['', '', ''],
      currentOrder: {},
      masterList: [],
      pagination: {
        current: 1,
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
        showSizeChanger: true,
      },
      tableLoading: false,
      center: [0, 0],
      rank: '默认',
      allCity: false,
      keyWord: '',
      working: true,
    }
  }
  render() {
    const { visible, provinces, masterList, pagination, center, rank, allCity, keyWord, currentOrder,
      tableLoading, working } = this.state;
    const columns = [
      {
        title: '师傅姓名',
        align: 'center',
        width: 100,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '师傅手机',
        align: 'center',
        width: 140,
        dataIndex: 'phone',
        key: 'phone',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div>{this.getWorkerPhone(record.phone, record.phone2)} </div>
              <div className={styles['icon_style']} onClick={this.changePhoneType.bind(this, record)}>{record.phone2 ? <EyeOutlined /> : <EyeInvisibleOutlined />}</div>
            </div>
          );
        }
        // <EyeOutlined /> : <EyeInvisibleOutlined />
      },
      {
        title: '服务区域',
        align: 'center',
        width: 100,
        dataIndex: 'district',
        key: 'district',
        render: (text, record, index) => {
          return (
            <div>{allCity ? record.district.join(',') : provinces[2]}</div>
          );
        }
      },
      {
        title: '服务范围',
        align: 'center',
        width: 200,
        dataIndex: 'work_type',
        key: 'work_type',
        render: (text, record, index) => {
          let type = record.work_type && record.work_type.length > 0 ? JSON.parse(record.work_type) : [];
          return (
            <div>{type.join()}</div>
          );
        }
      },
      {
        title: '师傅标签',
        align: 'center',
        width: 100,
        dataIndex: 'labels',
        key: 'labels',
        render: (text, record, index) => {
          let labels = record.labels && record.labels.length > 0 ? JSON.parse(record.labels) : [];
          return (
            <div>{labels.join()}</div>
          );
        }
      },
      {
        title: '师傅等级',
        align: 'center',
        width: 90,
        dataIndex: 'rank',
        key: 'rank',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.rank}</div>
              <div className='color2'>{record.order_status}</div>
            </div>
          );
        }
      },
      {
        title: '3个月累计接单',
        align: 'center',
        width: 100,
        dataIndex: 'orderNum',
        key: 'orderNum',
        sorter: {
          compare: (a, b) => a.orderNum - b.orderNum,
        },
        sortOrder: 'descend'
      },
      {
        title: '当月被投诉次数',
        align: 'center',
        width: 100,
        dataIndex: 'aftersaleNum',
        key: 'aftersaleNum',
      },
      {
        title: '备注信息',
        align: 'center',
        width: 330,
        dataIndex: 'remark',
        key: 'remark',
        render: (rext, record, index) => {
          let isExit = '';
          if (record.holiday && JSON.parse(record.holiday)) {
            let holiday = JSON.parse(record.holiday);
            isExit = '停工:' + holiday[0] + '；开工:' + holiday[1];
          }
          return (
            <div className='col'>
              <div>{record.remark}</div>
              <div>{isExit}</div>
            </div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 130,
        dataIndex: 'operate',
        key: 'operate',
        render: (rext, record, index) => {
          let orderService = currentOrder.addition ? currentOrder.addition.split(',') : [];
          let workService = record.work_type && record.work_type.length > 0 ? JSON.parse(record.work_type) : [];
          let conform = true;
          if (orderService.length > 0) {
            if (workService.length === 0) {
              conform = false;
            } else {
              orderService.map(item => {
                if (!workService.includes(item)) {
                  conform = false;
                }
              })
            }
          }
          return (
            <div className='w100 col'>
              <div className='col'>
                {/* <Button onClick={this.getDistance.bind(this, record.id)}
                  className='margin-r10 margin-b5'>
                  测距
                </Button> */}
                {
                  conform ?
                    <Popconfirm
                      title='确认派单给他'
                      okText='确认'
                      cancelText='取消'
                      onConfirm={this.orderToMaster.bind(this, record)}
                    >
                      <Button>派单给他</Button>
                    </Popconfirm>
                    :
                    <Popconfirm
                      title='该师傅没有与之匹配的技能类型 是否继续派单'
                      okText='确认'
                      cancelText='取消'
                      onConfirm={this.orderToMaster.bind(this, record)}
                    >
                      <Button>派单给他</Button>
                    </Popconfirm>
                }
              </div>
              {
                record.distance ?
                  <div className='w100 tl'>距离 {record.distance ? record.distance + 'KM' : ''}</div>
                  : <div></div>
              }
            </div>
          );
        }
      },
    ];
    // pagination.total = masterList.length;
    pagination.onChange = this.pageChange
    // let currentMasterList = masterList;
    let currentMasterList = [];
    if (rank === '全部') {
      currentMasterList = masterList;
    } else if (rank === '默认') {
      currentMasterList = masterList.filter(item => ['A', 'B', 'C'].includes(item.rank));
    } else {
      currentMasterList = masterList.filter(item => item.rank === rank);
    }
    if (!allCity) {
      currentMasterList = currentMasterList.filter(item => item.district.includes(provinces[2]))
      // currentMasterList = currentMasterList.filter(item => item.district === provinces[2])
    }
    // py
    if (keyWord) {
      currentMasterList = currentMasterList.filter(item => item.name.indexOf(keyWord.toUpperCase()) > -1)
    }
    if (working) {
      currentMasterList = currentMasterList.filter(item => item.order_status === '接单中');
    }
    return (
      <Drawer
        visible={visible}
        onClose={this.onCancel}
        width={1300}
      >
        <div className='col-t vertical-lt margin-r20'>
          <div className='row-l'>
            <div className='fz20 margin-r20'>服务区域</div>
            <Regin value={[provinces[0], provinces[1], provinces[2]]} onChange={this.changeProvince} />
            <div className='fz20 margin-l20'>师傅等级</div>
            <Select value={rank} onChange={this.changeRank} className='margin-l20 w80 margin-r20' size='large'>
              {
                rankList.map((item, index) => {
                  return <Option value={item} key={index} >{item}</Option>
                })
              }
            </Select>
            <Checkbox checked={allCity} onChange={this.changeAllCity} className='fz20 margin-l20'> 全市师傅</Checkbox>
          </div>
          <div className='row-l margin-t10 w100'>
            <div className='fz18'>名称搜索：</div>
            <Input value={keyWord} onChange={this.changeSearch} className='w150' />
            <Checkbox checked={!working} onChange={this.changeWork} className='fz20 margin-l20'> 全部师傅(接单)</Checkbox>
          </div>
        </div>
        <div className='margin-t20'>
          <Table
            columns={columns}
            dataSource={currentMasterList}
            size={'small'}
            rowKey={record => record.id + Math.random()}
            bordered
            total={currentMasterList.length}
            pagination={pagination}
            loading={tableLoading}
          />
        </div>
        <div className={`${styles['map']} margin-t20 w100 `}>
          <Map
            plugins={["ToolBar", 'Scale']}
            center={center}
            zoom={12}
          >
            {masterList ?
              masterList.map((item, index) => {
                return <Marker visiable={true} icon={bluemark} label={this.getMasterName(item.name)} position={[item.longitude, item.latitude]} key={index} />;
              })
              : ''
            }
            <Marker label={this.getMasterName('客户')} icon={redmark} visiable={true} position={center} key={-1} />
          </Map>
        </div>
      </Drawer>
    );
  }
  //废弃
  search = async () => {
    const { provinces, center } = this.state
    this.setState({
      tableLoading: true,
    });
    let customll = center[0] + ',' + center[1]
    let res = await getAllotMaster(provinces[0], provinces[1], provinces[2], customll, 0, 50);
    if (res.status === 200) {
      let masterList = res.data.result.map(item => {
        item.phone2 = false;
        return item;
      })
      if (res.data.result.length === 50) {
        let rsp = await getAllotMaster(provinces[0], provinces[1], provinces[2], customll, 50, 50);
        if (rsp.status === 200) {
          let masterList2 = rsp.data.result.map(item => {
            item.phone2 = false;
            return item;
          })
          masterList = masterList.concat(masterList2);
          this.setState({
            masterList,
            tableLoading: false,
          });
        } else {
          this.setState({
            masterList,
            tableLoading: false,
          });
        }
      } else {
        this.setState({
          masterList,
          tableLoading: false,
        });

      }
    } else {
      this.setState({
        tableLoading: false,
      });
    }
  }
  allSearch = async () => {
    const { provinces, center, } = this.state
    this.setState({
      tableLoading: true,
    });
    let masterList = _.cloneDeep(this.state.masterList);
    let customll = center[0] + ',' + center[1];
    let res = await getAllotMaster(provinces[0], provinces[1], provinces[2], customll, masterList.length, 50);
    if (res.status === 200) {
      res.data.result.map(item => {
        masterList.push({
          ...item,
          phone2: false,
        });
      })
      this.setState({
        masterList,
      }, () => {
        if (res.data.result.length === 50) {
          this.allSearch();
        } else {
          this.setState({
            tableLoading: false,
          });
        }
      });
    }
  }
  getWorkerPhone = (phone, type) => {
    if (!phone) {
      return;
    }
    let newPhone = phone;
    if (!type) {
      newPhone = phone.substring(0, 3) + '****' + phone.substring(7);
    }
    return newPhone;
  }
  changePhoneType = (e) => {
    let masterList = [...this.state.masterList];
    masterList.map((item, index) => {
      if (item.id === e.id) {
        masterList[index].phone2 = !masterList[index].phone2;
      }
    });
    this.setState({
      masterList
    });
  }
  getLngLnd = async (e) => {
    const { provinces } = this.state;
    let res = await toTitude(e);
    if (res.status === 200) {
      let locateIndex = 0;
      // location=res.data.geocodes[0].location;
      let check = true
      res.data.geocodes.map((item, index) => {
        if ((provinces === (item.province + item.city + item.district)) && check) {
          locateIndex = index
          check = false;
        }
      })
      let location = res.data.geocodes[locateIndex].location

      let longitude = Number(location.slice(0, location.indexOf(',')));
      let latitude = Number(location.slice(location.indexOf(',') + 1));
      this.setState({
        center: [longitude, latitude],
        masterList: [],
      }, () => this.allSearch())
    }
  }
  changeProvince = (e) => {
    this.setState({
      provinces: e,
      masterList: [],
    }, () => this.allSearch())
  }
  pageChange = (page, pageSize) => {
    let pagination = { ...this.state.pagination };
    pagination.current = page;
    pagination.pageSize = pageSize;
    this.setState({
      pagination
    });
  }
  changeSearch = (e) => {
    this.setState({
      keyWord: e.target.value
    });
  }
  //测距
  getDistance = async (worker) => {
    let masterList = _.cloneDeep(this.state.masterList);
    let res = await getDistance(this.state.currentOrder.id, worker);
    if (res.status === 200) {
      masterList.map((item, index) => {
        if (item.id === worker) {
          masterList[index].distance = res.data.distance + 'M';
        }
      })
      this.setState({
        masterList,
      });
    }
  }
  //派单
  orderToMaster = (e) => {
    this.props.updateTable(e);
    this.onCancel()
  }
  getMasterName = (e) => {
    return {
      content: e,
      direction: 'right'
    }
  }
  changeRank = (e) => {
    this.setState({
      rank: e
    });
  }
  changeAllCity = (e) => {
    this.setState({
      allCity: e.target.checked
    });
  }
  changeWork = (e) => {
    this.setState({
      working: !this.state.working
    });
  }
  // 关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      pagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
        showSizeChanger: true,
      },
      currentOrder: {},
      masterList: [],
      rank: '默认',
      allCity: false,
      keyWord: '',
      working: true,
    })
  }
  onShow = (e) => {
    this.setState({
      visible: true,
      provinces: [e.province, e.city, e.district],
      currentOrder: e,
    }, () => {
      let addresss = e.province + e.city + e.district + e.address
      this.getLngLnd(addresss)
    })
  }
}

export default MapModal;