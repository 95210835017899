import {
  ContainerOutlined,
  AuditOutlined,
  CloudServerOutlined,
  CloudDownloadOutlined,
  CheckSquareFilled,
  CustomerServiceFilled,
  AccountBookFilled,
  TeamOutlined,
  DatabaseFilled,
  BarChartOutlined,
  SendOutlined,
  AppstoreAddOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

// import OrderRenter from '../../routes/orderRenters';
// import NewOrder from '../../routes/newOrder';
// import AfterSale from '../../routes/afterSale';
// import Finance from '../../routes/finance';
// import StoreManagement from '../../routes/storeManagement';
// import ServiceArea from '../../routes/serviceArea';
import AllOrder from '../../routes/orderCore/allOrder';
import NoAppointmentOrder from '../../routes/orderCore/noAppointmentOrder';
import UrgentOrder from '../../routes/orderCore/urgentOrder';
import Reminder from '../../routes/orderCore/reminder';
import GoodOrder from '../../routes/orderCore/goodOrder';
import CheckOrder from '../../routes/orderCore/checkOrder';
import Feedback from '../../routes/orderCore/feedback';
import TimeoutOrder from '../../routes/orderCore/timeoutOrder/timeoutOrder';
import Task from '../../routes/task';
import SumOrder from '../../routes/orderCore/sumOrder';
import AfterSales from '../../routes/afterSales';
import MeasureData from '../../routes/measureData/index';
import MasterManagement from '../../routes/masterManagement/index';
import RecommendMaster from '../../routes/recommendMaster/index';
import ShopManagement from '../../routes/shopManagement/index';
import ServiceManagement from '../../routes/serviceManagement/index';
import CouponsManagement from '../../routes/couponsManagement/index';
import NoticeManagement from '../../routes/noticeManagement/index';
import OptionManagement from '../../routes/optionManagement/index';
import WorkerFinance from '../../routes/orderFinance/workerFinance';
import WorkerWithdrawal from '../../routes/orderFinance/workerWithdrawal';
import ReceivedOrder from '../../routes/orderFinance/receivedOrder';
import TemplateManagement from '../../routes/templateManagement/index';
import LabelManagement from '../../routes/labelManagement/index';
import PlatformOrder from '../../routes/analysisCenter/platformCenter';
import PlatformOrder2 from '../../routes/analysisCenter/platformCenter2';
import ShopOrder from '../../routes/analysisCenter/shopOrder';
import ShopOrder2 from '../../routes/analysisCenter/shopOrder2';
import MasterOrder from '../../routes/analysisCenter/masterOrder';
import Finance from '../../routes/analysisCenter/finance';
import AfterSale from '../../routes/analysisCenter/afterSale';
import BusinessDevelopment from '../../routes/analysisCenter/businessDevelopment';
import ServicerCapacity from '../../routes/analysisCenter/servicerCapacity';
import SettleData from '../../routes/analysisCenter/settleData';
import MasterMap from '../../routes/masterMap/index';
// import ShoppingMall from '../../routes/shoppingMall';
import NewOrder from '../../routes/newOrder';
import Panels from '../../routes/panels/panels';
import SingleOrdeer from '../../routes/analysisCenter/singleOrder';
import SingleOrdeer2 from '../../routes/analysisCenter/singleOrder2';
import MasterSign from '../../routes/masterSign/masterSign';
import MasterLabel from '../../routes/masterLabel/masterLabel';
import AreaCompare from '../../routes/analysisCenter/areaCompare';
import WhiteList from '../../routes/whiteList/index';
import LoginPermiss from '../../routes/loginPermiss/index';
import LoginHistory from '../../routes/loginHistory/loginHistory';
import SizeManagement from '../../routes/sizeManagement/sizeManagement';
import MasterAreaRank from '../../routes/masterManagement/masterAreaRank';
import Reward from '../../routes/reward';
import WorkRemards from '../../routes/workRemards';
import Feed from '../../routes/feed';
import InviteSign from '../../routes/inviteSign';

export const assembly = [
  {
    path: 'summary',
    name: '工作台',
    icon: <AppstoreAddOutlined />
  },
  {
    path: 'orderRenter',
    name: '订单中心',
    icon: <ContainerOutlined />,
    childRoutes: [
      {
        path: 'allOrder',
        name: '全部订单汇总',
      },
      {
        path: 'sumOrder',
        name: '分类订单汇总',
      },
      // {
      //   path: 'noAppointmentOrder',
      //   name: '6小时内未预约订单',
      // },
      // {
      //   path: 'urgentOrder',
      //   name: '加急订单汇总',
      // },
      // {
      //   path: 'reminder',
      //   name: '催单汇总',
      // },
      // {
      //   path: 'timeoutOrder',
      //   name: '超时未验收订单',
      // },
      {
        path: 'goodOrder',
        name: '返现订单汇总',
      },
      {
        path: 'feedback',
        name: '反馈处理',
      },
      // {
      //   path: 'checkOrder',
      //   name: '订单审核',
      // },
    ]
  },
  {
    path: 'newOrder',
    name: '代客户下单',
    icon: <TeamOutlined />
  },

  // {
  //   path: 'task',
  //   name: '我的任务',
  //   icon: <CheckSquareFilled />
  // },
  {
    path: 'afterSales',
    name: '售后中心',
    icon: <CustomerServiceFilled />
  },
  {
    path: 'measureData',
    name: '测量数据',
    icon: <CloudDownloadOutlined />
  },
  {
    path: 'master',
    name: '师傅维护',
    icon: <AuditOutlined />,
    childRoutes: [
      {
        path: 'masterManagement',
        name: '师傅信息',
      },
      {
        path: 'workRemards',
        name: '师傅奖惩',
      },
      {
        path: 'masterAreaRank',
        name: '师傅服务区域',
      },
      {
        path: 'noAppointmentOrder',
        name: '推荐师傅',
      },
      {
        path: 'masterSign',
        name: '签约师傅',
      },
      {
        path: 'inviteSign',
        name: '申请审批',
      },
    ]
  },
  {
    path: 'shopManagement',
    name: '商户维护',
    icon: <AuditOutlined />
  },
  {
    path: 'serviceManagement',
    name: '客服维护',
    icon: <CloudServerOutlined />
  },
  {
    path: 'orderFinance',
    name: '订单财务',
    icon: <AccountBookFilled />,
    childRoutes: [
      {
        path: 'workerFinance',
        name: '师傅余额',
      },

      {
        path: 'workerWithdrawal',
        name: '师傅提现',
      },
      // {
      //   path: 'receivedOrder',
      //   name: '待收款订单',
      // },
    ]
  },
  {
    path: 'baseData',
    name: '基础数据',
    icon: <DatabaseFilled />,
    childRoutes: [
      {
        path: 'optionManagement',
        name: '基础选项维护',
      },
      {
        path: 'feed',
        name: '反馈问题',
      },
      {
        path: 'templateManagement',
        name: '价格模板维护',
      },
      {
        path: 'reward',
        name: '奖惩维护',
      },
      {
        path: 'sizeManagement',
        name: '成品尺寸扣减',
      },
      {
        path: 'couponsManagement',
        name: '通知及资料维护',
      },
      {
        path: 'noticeManagement',
        name: '优惠券维护',
      },
      {
        path: 'labelManagement',
        name: '订单标签维护',
      },
      {
        path: 'MasterLabel',
        name: '师傅标签维护',
      },
    ]
  },
  {
    path: 'analysisCenter',
    name: '分析中心',
    icon: <BarChartOutlined />,
    childRoutes: [
      {
        path: 'platformOrder',
        name: '平台订单分析',
      },
      {
        path: 'platformOrder2',
        name: '平台下单分析',
      },
      {
        path: 'areaCompare',
        name: '各区数据对比',
      },
      {
        path: 'singleOrder',
        name: '单日订单结算分析',
      },
      {
        path: 'singleOrder2',
        name: '单日订单结算分析2',
      },
      {
        path: 'shopOrder',
        name: '商户下单分析',
      },
      {
        path: 'shopOrder2',
        name: '商户结算订单分析',
      },
      {
        path: 'settleData',
        name: '数据整理分析',
      },
      {
        path: 'masterOrder',
        name: '师傅订单分析',
      },
      {
        path: 'finance',
        name: '财务分析',
      },
      {
        path: 'afterSale',
        name: '售后分析',
      },
      {
        path: 'businessDevelopment',
        name: '业务开发分析',
      },
      {
        path: 'servicerCapacity',
        name: '客服产能分析',
      },
    ]
  },
  {
    path: 'accountSafe',
    name: '账号安全',
    icon: <InfoCircleOutlined />,
    childRoutes: [
      {
        path: 'loginPermiss',
        name: '登录授权',
      },
      {
        path: 'WhiteList',
        name: '白名单维护',
      },
      {
        path: 'loginHistory',
        name: '用户登录历史',
      },
    ]
  },
  {
    path: 'MasterMap',
    name: '服务区域',
    icon: <SendOutlined />
  },

];

export const getChildren = (type, fn, typeName) => {
  switch (type) {
    case '工作台':
      return <Panels fun={fn} />
    case '代客户下单':
      return <NewOrder fun={fn} />
    case '售后中心':
      return <AfterSales fun={fn} />
    case '全部订单汇总':
      return <AllOrder fun={fn} />
    case '分类订单汇总':
      return <SumOrder fun={fn} />
    case '6小时内未预约订单':
      return <NoAppointmentOrder />
    case '加急订单汇总':
      return <UrgentOrder fun={fn} />
    case '催单汇总':
      return <Reminder fun={fn} />
    case '超时未验收订单':
      return <TimeoutOrder fun={fn} />
    case '订单审核':
      return <CheckOrder />
    case '返现订单汇总':
      return <GoodOrder />
    case '反馈处理':
      return <Feedback />
    case '测量数据':
      return <MeasureData fun={fn} />
    case '师傅信息':
      return <MasterManagement />
    case '师傅奖惩':
      return <WorkRemards />
    case '师傅服务区域':
      return <MasterAreaRank />
    case '推荐师傅':
      return <RecommendMaster />
    case '签约师傅':
      return <MasterSign />
    case '申请审批':
      return <InviteSign />
    case '商户维护':
      return <ShopManagement />
    case '客服维护':
      return <ServiceManagement />
    case '通知及资料维护':
      return <NoticeManagement />
    case '优惠券维护':
      return < CouponsManagement />
    case '奖惩维护':
      return < Reward />
    case '基础选项维护':
      return < OptionManagement />
    case '反馈问题':
      return < Feed />
    case '价格模板维护':
      return < TemplateManagement />
    case '订单标签维护':
      return < LabelManagement />
    case '师傅标签维护':
      return < MasterLabel />
    case '成品尺寸扣减':
      return < SizeManagement />

    case '师傅余额':
      return <WorkerFinance />
    case '师傅提现':
      return <WorkerWithdrawal />
    case '待收款订单':
      return <ReceivedOrder />

    case '平台订单分析':
      return <PlatformOrder />
    case '平台下单分析':
      return <PlatformOrder2 />
    case '各区数据对比':
      return <AreaCompare />
    case '单日订单结算分析':
      return <SingleOrdeer />
    case '单日订单结算分析2':
      return <SingleOrdeer2 />
    case '商户下单分析':
      return <ShopOrder />
    case '商户结算订单分析':
      return <ShopOrder2 />
    case '数据整理分析':
      return <SettleData />
    case '师傅订单分析':
      return <MasterOrder />
    case '财务分析':
      return <Finance />
    case '售后分析':
      return <AfterSale />
    case '业务开发分析':
      return <BusinessDevelopment />
    case '客服产能分析':
      return <ServicerCapacity />

    case '服务区域':
      return <MasterMap />
    case '白名单维护':
      return <WhiteList />
    case '登录授权':
      return <LoginPermiss />
    case '用户登录历史':
      return <LoginHistory />
  }
}