import React, { Component } from 'react';
import styles from './index.module.css';
import { CheckCircleFilled } from '@ant-design/icons';
import { message, Table, Input, Radio, Image, Select, Modal, Button, Popconfirm, } from 'antd';
import del from '../../resource/png/masterManagement-del.png';
import links from '../../resource/png/noticeManagement.png';
import UploadFile from '../../components/uploadFile';
import UploadPic from '../../components/UploadPic';
import PageTurning from '../../components/pageTurning';
import { getNotice, addNotice, editNotice, delNotice } from '../../services/noticeManagement';
import { getDepartment_Data, getOssBaseAuth } from '../../config/storage';
import { ossUpload, ossUploadNotice } from '../../utils/ossUpload';
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;
const enableList = ['全部', '启用', '禁用'];
const enableList2 = ['启用', '禁用'];
const notifyList = ['全部', '师傅端', '商户端', '客服端'];
const notifyList2 = ['师傅端', '商户端', '客服端'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: '',
      enable: '全部',
      notify: '全部',
      skip: 0,
      take: 10,
      noticeList: [],
      inform: {
        id: 0,
        type: '通知',
        name: '',
        content: '',
        url: '',
        enable: '是',
        ascription: '师傅端',
      },
      informType: 'add',
      informModal: false,
      myOss: {
        host: '',
        expiration: 0,
        key: '',
        policy: '',
        prefix: '',
        signature: '',
      },
    };
    this.pageModal = React.createRef();
    this.baseUrl = getDepartment_Data.service + '/upload/';
  }
  async componentDidMount() {
    this.getNoticeList();
    let res = getDepartment_Data();
    let myOss = getOssBaseAuth();
    this.setState({
      fileUrl: `${res.service}/notification/`,
      myOss
    })
  }
  render() {
    const { enable, notify, informModal, inform, informType, noticeList, myOss } = this.state;
    const colums = [
      {
        title: '标题',
        align: 'center',
        width: 300,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '内容',
        align: 'center',
        width: 600,
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 300,
        dataIndex: 'enable',
        key: 'enable',
      },
      {
        title: '发送端口',
        align: 'center',
        width: 300,
        dataIndex: 'ascription',
        key: 'ascription',
      },
      {
        title: '发送类型',
        align: 'center',
        width: 300,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '发送时间',
        align: 'center',
        width: 300,
        dataIndex: 'submit_time',
        key: 'submit_time',
        render: (text, record, index) => {
          return (<div>{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 300,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='w100 row'>
              <div onClick={this.editInform.bind(this, record)} className={`${styles['btn']} back-color8 margin-r10 color0 cursor-pointer`}>编辑</div>
              <div onClick={this.deleteNotice.bind(this, record)} className={`${styles['btn']} back-color11 color0 cursor-pointer`}>删除</div>
            </div>
          );
        }
      },
    ];
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className=' row-l '>
            <div className='margin-l20 fz18'>启用状态：</div>
            <Select onSelect={this.changeSummary.bind(this, 'enable')} value={enable} className={styles['input']} bordered={false}>
              {
                enableList.map((item, indexs) => {
                  return (
                    <Option key={indexs} value={item}>{item}</Option>
                  );
                })
              }
            </Select>
            <div className='margin-l20 fz18'>通知端口：</div>
            <Select onSelect={this.changeSummary.bind(this, 'notify')} value={notify} className={styles['input']} bordered={false}>
              {
                notifyList.map((item, indexs) => {
                  return (
                    <Option key={indexs} value={item}>{item}</Option>
                  );
                })
              }
            </Select>
            <div onClick={this.toSearch} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >搜索</div>
            <div onClick={this.showInformModal.bind(this, 'add', '通知')} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >新增通知</div>
            <div onClick={this.showInformModal.bind(this, 'add', '资料')} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >新增资料</div>
            <div onClick={this.showInformModal.bind(this, 'add', '公告')} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >新增公告</div>
          </div>
        </div>
        <Table
          columns={colums}
          dataSource={noticeList}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
        />
        <div className='back-color0 w100'>
          <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.pageModal} />
        </div>
        <Modal
          title={inform.type === '通知' ? '通知内容编辑' : '相关资料上传'}
          visible={informModal}
          width={500}
          okText='确认'
          cancelText='取消'
          onCancel={this.hideInformModal}
          onOk={this.addNewNotice}
        >
          <div className='col-t vertical-lt w100'>
            <div className='row-l margin-b20'>
              <div className='w80'>{inform.type === '通知' ? '通知标题：' : '资料名称：'}</div>
              <Input onChange={this.changeSummary.bind(this, 'name')} value={inform.name} placeholder={inform.type === '通知' ? '请输入标题' : '请输入名称'} className='w360' />
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>通知对象：</div>
              <Radio.Group onChange={this.changeSummary.bind(this, 'ascription')} value={inform.ascription} size='large'>
                {
                  notifyList2.map((item, index) => {
                    return <Radio value={item} key={index} checked={item === inform.ascription} className='fz16 margin-l5'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>{inform.type === '通知' ? '通知内容：' : '资料简介：'}</div>
              <TextArea onChange={this.changeSummary.bind(this, 'content')} value={inform.content} placeholder={inform.type === '通知' ? '请输入内容' : '请输入简介'} className='w360 h120' />
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>启用状态：</div>
              <Radio.Group disabled={informType === 'add'} onChange={this.changeSummary.bind(this, 'addEnable')} value={inform.enable === '是' ? '启用' : '禁用'} size='large'>
                {
                  enableList2.map((item, index) => {
                    return <Radio value={item} key={index} checked={inform.enable === '是' && item === '启用'} className='fz16 margin-l5'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            {
              inform.type === '资料' ?
                <div className='row-l margin-b20'>
                  <div className='w80'>上传资料：</div>
                  <UploadFile
                    onChange={this.uploadNotice}
                  />
                  {
                    inform.url ?
                      <div className='row-l'>
                        <Image onClick={this.toResource} title='查看文件' className={`${styles['icon']} margin-l10 cursor-pointer`} src={links} preview={false} />
                        <Image onClick={this.delFile} className={`${styles['icon']} margin-l10 cursor-pointer`} src={del} preview={false} />
                      </div>
                      : ''
                  }
                </div>
                : ''
            }
            {
              inform.type === '通知' &&
              <div>
                <UploadPic
                  oss={myOss.host}
                  src={inform.url}
                  upLoad={this.upLoadIdPic}
                  deletePic={this.delIdPic}
                  size={'small'}
                  className={styles['box']}
                />
              </div>
            }
          </div>
        </Modal>
      </div >
    );
  }
  toSearch = () => {
    this.setState({
      skip: 0,
    }, () => {
      this.getNoticeList()
      this.pageModal.current.returnFirst();
    })
  }
  getNoticeList = async () => {
    const { enable, notify, skip, take } = this.state;
    let newEnable = enable === '全部' ? enable : enable === '启用' ? '是' : '否';
    let res = await getNotice(newEnable, notify, '全部', '', skip, take);
    if (res.status === 200) {
      this.setState({
        noticeList: res.data
      });
    }
  }
  changeSummary = (type, e) => {
    let inform = { ...this.state.inform };
    switch (type) {
      case 'enable':
        this.setState({
          enable: e
        });
        break;
      case 'notify':
        this.setState({
          notify: e
        });
        break;
      case 'name':
        inform.name = e.target.value;
        this.setState({
          inform
        });
        break;
      case 'ascription':
        inform.ascription = e.target.value;
        this.setState({
          inform
        });
        break;
      case 'content':
        inform.content = e.target.value;
        this.setState({
          inform
        });
        break;
      case 'addEnable':
        inform.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          inform
        });
        break;
    }
  }
  upLoadIdPic = async (file) => {
    let inform = { ...this.state.inform };
    let name = await ossUpload(file, null);
    inform.url = name;
    this.setState({
      inform
    });
  }
  delIdPic = () => {
    let inform = { ...this.state.inform };
    inform.url = '';
    this.setState({
      inform
    });
  }
  addNewNotice = async () => {
    const { informType, inform } = this.state;
    if (inform.name === '') {
      message.error('标题未填写');
      return;
    }
    if (informType === 'add') {
      let res = await addNotice(inform);
      if (res.status !== 200) { return; }
    } else {
      let res = await editNotice(inform.id, inform);
      if (res.status !== 204) { return; }
    }
    this.hideInformModal()
    this.getNoticeList()
  }
  editInform = (e) => {
    this.setState({
      inform: e,
      informType: 'edit',
      informModal: true,
    })
  }
  uploadNotice = async (e) => {
    let name = await ossUploadNotice(e.file, null);
    let inform = { ...this.state.inform };
    inform.url = name;
    this.setState({
      inform,
    })
  }
  delFile = () => {
    let inform = { ...this.state.inform };
    inform.url = '';
    this.setState({
      inform,
    })
  }
  toResource = () => {
    const { inform, fileUrl } = this.state;
    window.open(fileUrl + inform.url);
  }
  deleteNotice = (e) => {
    Modal.confirm({
      title: '确认删除此项通知吗？',
      okText: '是',
      cancelText: '否',
      onOk: async () => {
        let res = await delNotice(e.id);
        if (res.status === 204) {
          this.getNoticeList()
        }
      }
    })
  }




  showInformModal = (type, sort) => {
    let inform = { ...this.state.inform };
    inform.type = sort
    this.setState({
      inform,
      informType: type,
      informModal: true,
    });
  }
  hideInformModal = () => {
    this.setState({
      informModal: false,
      inform: {
        id: 0,
        type: '通知',
        name: '',
        content: '',
        url: '',
        enable: '是',
        ascription: '师傅端',
      },
    });
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getNoticeList())
  }
}

export default Index;