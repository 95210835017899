import React, { Component } from 'react';
import { Input, Modal, Radio, message, InputNumber, Table, Button } from 'antd';
import styles from './modal.module.css';
import { getReduce, delReduce } from '../../services/sizeManagement';
import _ from 'lodash';
import { valid } from 'semver';


const remarks2 = [
  {
    key: '普通轨道已安装',
    list: ['双层普通轨道已安装每层有走珠（） 个', '单层普通轨道已安装有走珠（）个'],
  },
  {
    key: '双开电动轨道已安装',
    list: ['双层电动轨道已安装，每层有走珠（）个不含两边固定环和滑车臂环，（）宽度不含滑车臂长（）公分',
      '单层电动轨道已安装，有走珠（）个不含两边固定环和滑车臂环，（）宽度不含滑车臂长（）公分'],
  },
  {
    key: '单开电动轨道已安装',
    list: ['双层电动轨道已安装，每层有走珠（）个不含一边固定环和滑车臂环',
      '单层电动轨道已安装，有走珠（）个不含一边固定环和滑车臂环'],
  },
  {
    key: '罗马杆已安装',
    list: ['双层罗马杆已安装每层有吊环（）个', '单层罗马杆已安装 有吊环（）个',
      '双层罗马杆已安装其中一层有吊环（）个'],
  },
  {
    key: '电动梦幻帘轨道已安装',
    list: ['电动梦幻帘轨道已安装共有走珠（）个'],
  },
  {
    key: '电动梦幻帘轨道未安装',
    list: ['暖气片/窗台石凸出墙面（）公分', '内开窗上沿距盒顶/房顶间距（）公分', '柜门上沿距盒顶/房顶间距（）公分'],
  },
  {
    key: '磁控百叶',
    list: ['把手到玻璃框边宽度（）公分', '上边框宽（）公分，下边框宽（）公分，左边框宽（）公分，右边框宽（）公分'],
  },
  {
    key: '隐形轨道',
    list: ['已经有双/单层隐形轨道槽，槽宽（）公分*高（）公分', '单/双层隐形轨道已安装，每层有走珠（）个'],
  },
  {
    key: '内开窗',
    list: ['内开窗上沿间距（）公分'],
  },
  {
    key: '缺口尺寸',
    list: ['左/右上角有个宽（）公分*高（）公分的梁，（）宽度不含梁宽',
      '阳台左和右之间有个宽（）公分*高（）公分的梁，（）宽度不含梁宽',
      '左/右下角有个宽个宽（）公分*高（）公分的台子，（）宽度不含台子宽',
      '阳台左和右之间有个梁，梁的位置客户不做'
    ],
  },
  {
    key: '两种高度',
    list: ['左边宽（）对应的高度为（），右边宽（）对应的高度为（）', '窗台面凸出（）公分 纱帘做到小窗台面 布帘做到地面'],
  },
  {
    key: '成品帘',
    list: ['把手凸出（）公分', '内开窗上沿间距（）公分，成品帘收起高度不能超过（）公分',
      'L/U/直线/型窗分段尺寸为（）  注意扣减转角尺寸'
    ],
  },
  {
    key: '大小片',
    list: ['左边宽（）位置窗帘做一片，右边宽（）位置窗帘做一片'],
  },
  {
    key: '柜子门或者柜子',
    list: ['左/右边柜子离墙面间距（）公分', '左/右边柜子开门上沿上沿距顶间距（）公分，柜子开门距墙面间距（）公分',
      '左/右边空调离墙面间距（）公分'
    ],
  },
];
class SpecialModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentData: {
        remark: '',
      },
      index: -1,
      remarks: [],
    }
    this.columns = [];

  }
  render() {
    const { visible, currentData, remarks } = this.state;
    return (
      <Modal
        title='特殊备注'
        visible={visible}
        // centered
        onCancel={this.onCancel}
        onOk={this.onOk}
        width={900}
        okText='确定'
        cancelText='取消'
      >
        <div className='col-t vertical-lt'>
          <Input.TextArea placeholder='请输入特殊备注' value={currentData.remark}
            onChange={this.changeRemark} className='' rows={4} />
          <div className='margin-t10 bord3 scroll-y h400 w850 none-select'>
            {
              remarks.map((item, index) => {
                return (
                  <div className={`${styles['border-all']} row-l solid1`} key={index}>
                    <div className='w160 padding-l10'>{item.key}</div>
                    <div className='col-t vertical-lt w680 bord-l1'>
                      {
                        item.list.map((litem, lindex) => {
                          return (
                            <div key={lindex} onClick={this.addRemark.bind(this, litem)}
                              className={`${styles['border-all']} ${styles['remark-info']} ${styles['toSelect']} w680`}>
                              {litem}
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </Modal>
    );
  };
  changeRemark = (e) => {
    let currentData = _.cloneDeep(this.state.currentData);
    currentData.remark = e.target.value;
    this.setState({
      currentData
    });
  }
  addRemark = (e) => {
    let currentData = _.cloneDeep(this.state.currentData);
    currentData.remark = currentData.remark ? currentData.remark + '；' + e : e;
    currentData.remark = currentData.remark + '；';
    this.setState({
      currentData
    });
  }
  onShow = (data, index) => {
    this.onShowRemarks(data);
    this.setState({
      visible: true,
      currentData: data,
      index,
    });
  }
  onShowRemarks = (data) => {
    let plan = data.prop1 + data.prop2;
    let remarks = [];
    switch (plan) {
      case '直轨已安装':
      case '弯轨已安装':
        remarks = [
          {
            key: '直轨弯轨已安装',
            list: ['双层普通轨道已安装每层有走珠（）个',
              '单层普通轨道已安装 有走珠（）个',
              '需要加装一层轨道，加装位置（）公分，已安装的轨道要移位安装'],
          }
        ];
        break;
      case '电动直轨已安装':
      case '电动弯轨已安装':
        remarks = [
          {
            key: '电动直轨、电动弯轨已安装',
            list: ['双层电动轨道已安装，每层有走珠（）个不含两边固定环和滑车臂环，（）宽度不含滑车臂长（）公分',
              '单层电动轨道已安装，有走珠（）个不含两边固定环和滑车臂环，（）宽度不含滑车臂长（）公分',
              '双层电动轨道已安装，每层有走珠（）个不含一边固定环和滑车臂环',
              '单层电动轨道已安装，有走珠（）个不含一边固定环和滑车臂环',
              '需要加装一层轨道，加装位置（）公分，已安装的轨道要移位安装',
            ],
          }
        ];
        break;
      case '罗马杆已安装':
        remarks = [
          {
            key: '罗马杆已安装',
            list: ['双层罗马杆已安装每层有吊环（）个',
              '单层罗马杆已安装 有吊环（）个',
              '双层罗马杆已安装其中一层有吊环（）个',
            ],
          }
        ];
        break;
      case '电动梦幻帘已安装':
        remarks = [
          {
            key: '电动梦幻帘已安装',
            list: ['电动梦幻帘轨道已安装共有走珠（）个',
              '暖气片 / 窗台石凸出墙面（）公分',
              '内开窗上沿距盒顶 / 房顶间距（）公分',
              '柜门上沿距盒顶 / 房顶间距（）公分',
            ],
          }
        ];
        break;
      case '电动梦幻帘顶装':
      case '电动梦幻帘侧装':
        remarks = [
          {
            key: '电动梦幻帘未安装',
            list: ['暖气片 / 窗台石凸出墙面（）公分',
              '内开窗上沿距盒顶 / 房顶间距（）公分',
              '柜门上沿距盒顶 / 房顶间距（）公分',
            ],
          }
        ];
        break;
      case '磁控百叶内嵌式安装':
      case '磁控百叶玻璃内框尺寸':
        remarks = [
          {
            key: '磁控百叶',
            list: ['把手到玻璃框边宽度（）公分',
              '上边框宽（）公分，下边框宽（）公分，左边框宽（）公分，右边框宽（）公分',
              '斜边框师傅用小样测量外沿成品尺寸',
            ],
          }
        ];
        break;
      case '隐形轨道顶装':
      case '隐形轨道已安装':
        remarks = [
          {
            key: '隐形轨道',
            list: ['已经有单层隐形轨道槽，槽宽（）公分 * 高（）公分',
              '已经有双层隐形轨道槽，槽宽（）公分 * 高（）公分',
              '单层隐形轨道已安装，每层有走珠（）个',
              '双层隐形轨道已安装，每层有走珠（）个',
            ],
          }
        ];
        break;
      case '成品帘内顶装':
      case '成品帘外顶装':
      case '成品帘内两头装':
      case '成品帘外两头装':
      case '成品帘外侧装':
      case '成品帘内侧装':
      case '成品帘内嵌式安装':
      case '成品帘免打孔':
        remarks = [
          {
            key: '成品帘',
            list: ['把手凸出（）公分',
              '内开窗上沿间距（）公分，成品帘收起高度不能超过（）公分',
              'L / U / 直线 / 型窗分段尺寸为（）  注意扣减转角尺寸',
              '框内顶上槽宽（）公分',
            ],
          }
        ];
        break;
      case '罗马杆顶装':
      case '罗马杆侧装':
      case '罗马杆侧装|':
      case '罗马杆侧装|是':
      case '罗马杆侧装|否':
      case '罗马杆两头装':
      case '直轨顶装':
      case '直轨侧装':
      case '电动直轨顶装':
      case '电动直轨侧装':
      case '弯轨顶装':
      case '弯轨侧装':
      case '电动弯轨顶装':
      case '电动弯轨侧装':
        remarks = [
          {
            key: '内开窗',
            list: ['内开窗上沿间距（）公分'],
          },
          {
            key: '缺口尺寸',
            list: ['左 / 右上角有个宽（）公分 * 高（）公分的梁，（）宽度不含梁宽',
              '阳台左和右之间有个宽（）公分 * 高（）公分的梁，（）宽度不含梁宽',
              '左 / 右下角有个宽个宽（）公分 * 高（）公分的台子，（）宽度不含台子宽',
              '阳台左和右之间有个梁，梁的位置客户不做',
            ],
          },
          {
            key: '两种高度',
            list: ['左边宽（）对应的高度为（），右边宽（）对应的高度为（）',
              '窗台面凸出（）公分 纱帘做到小窗台面 布帘做到地面',
            ],
          },
          {
            key: '大小片',
            list: ['窗帘做大小片，左边宽（）位置窗帘做一片，右边宽（）位置窗帘做一片',
              '窗帘做大小片，在正面的中心位置对开',
            ],
          },
          {
            key: '柜子门或者柜子',
            list: ['左 / 右边柜子离墙面间距（）公分',
              '左 / 右边柜子开门上沿上沿距顶间距（）公分，柜子开门距墙面间距（）公分',
              '左 / 右边空调离墙面间距（）公分',
            ],
          },
          {
            key: '',
            list: ['地板未铺  地板厚度已扣减（）公分',
              '地板未铺  地板厚度未扣减',
              '安装位置超高，需要客户备好架子才能安装',
              '飘窗垫厚度跟客户确认扣减',
            ],
          },
        ];
        break;
      // 未安装显示（直轨弯轨罗马杆电动直轨电动弯轨未安装）
      case '手动梦幻帘已安装':
        remarks = [
          {
            key: '手动梦幻帘轨道已安装',
            list: ['手动梦幻帘轨道已安装共有走珠（）个',
              '暖气片/窗台石凸出墙面（）公分',
              '内开窗上沿距盒顶/房顶间距（）公分',
              '柜门上沿距盒顶/房顶间距（）公分',
            ],
          },
        ];
        break;
      case '手动梦幻帘顶装':
      case '手动梦幻帘侧装':
        remarks = [
          {
            key: '手动梦幻帘轨道已安装',
            list: ['暖气片/窗台石凸出墙面（）公分',
              '内开窗上沿距盒顶/房顶间距（）公分',
              '柜门上沿距盒顶/房顶间距（）公分',
            ],
          },
        ];
        break;
      //   手动梦幻帘轨道已安装：
      // 1.手动梦幻帘轨道已安装共有走珠（）个
      // 2.暖气片/窗台石凸出墙面（）公分
      // 3.内开窗上沿距盒顶/房顶间距（）公分
      // 4.柜门上沿距盒顶/房顶间距（）公分
      // 手动梦幻帘轨道未安装【顶装、侧装】：
      // 1.暖气片/窗台石凸出墙面（）公分
      // 2.内开窗上沿距盒顶/房顶间距（）公分
      // 3.柜门上沿距盒顶/房顶间距（）公分
    }
    this.setState({
      remarks,
    })

  }
  onCancel = () => {
    this.setState({
      visible: false,
      index: -1,
    });
  }
  onOk = () => {
    const { currentData, index } = this.state;
    this.props.upload(index, currentData);
    this.onCancel();
  }

}
export default SpecialModal;