import React, { Component } from 'react';
import { Modal, Input, Table, message, Button } from 'antd';
import { getWorker, getShop, getManager } from '../../../services/orderCore';
import { getTemplate } from '../../../services/templateManagement';
import PageTurning from '../../../components/pageTurning';

const { Search } = Input;

class WorkerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '师傅',
      visible: false,
      info: '',
      confirmLoading: false,
      skip: 0,
      take: 10,
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      city: '',
    }
    this.pageTurning = React.createRef();
    this.pageSize = 10;
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
    ]
  }
  render() {
    const { type, visible, info, data, selectedRowKeys, confirmLoading } = this.state;
    const { } = this.props;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'checkbox',
      onChange: this.onSelectChange,
    };
    return (
      <Modal
        title={`${type}选择`}
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <Input
          placeholder={`请输入${type}信息`}
          value={info}
          className='w400'
          onChange={this.changeInfo}
        />
        <Button onClick={this.getData} type='primary'>搜索</Button>
        <div className='w100 margin-t10 back-color0'>
          <Table
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={type === '客服'}
          />
          {
            type !== '客服' &&
            <PageTurning ref={this.pageTurning} pageMaps={this.pageMaps} />
          }
        </div>
      </Modal>
    );
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value,
      skip: 0,
    });
    if (this.state.type !== '客服') {
      this.pageTurning.current.returnFirst();
    }
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    if (this.props.multiple) {
      this.setState({
        selectedRowKeys,
        selectedRows,
      });
    } else {
      const diff = _.difference(selectedRowKeys, this.state.selectedRowKeys);
      if (diff.length > 0) {
        this.setState({
          selectedRowKeys: diff
        });
      } else {
        this.setState({
          selectedRowKeys: selectedRowKeys
        });
      }
    }
  }
  onShowModal = (type, city) => {
    let citys = city || '';
    this.setState({
      type: type,
      visible: true,
      city: citys,
      skip: 0
    }, async () => this.getData());
  }
  getData = async () => {
    let { skip, take, info } = this.state;
    let res;
    if (this.state.type === '师傅') {
      res = await getWorker({ name: info, city: this.state.city, skip, take })
    } else if (this.state.type === '商户') {
      res = await getShop({ name: info, skip, take })
    } else if (this.state.type === '模板') {
      res = await getTemplate('是', skip, take, info)
    } else {
      res = await getManager({ name: info, skip: 0, take: 200 })
    }
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let data = res.data;
    if (!this.props.single) {
      data.unshift({
        name: '全部',
        id: 0
      })
    }
    this.setState({
      data: res.data,
    })
  }
  // 完成
  onOk = () => {
    this.setState({
      confirmLoading: true,
    })
    let { data, selectedRowKeys, type } = this.state;
    let selectedRows = data.filter(r => r.id === selectedRowKeys[0])[0];
    this.props.updateTable(selectedRows, type);
    this.onCancel();
    this.setState({
      confirmLoading: false,
    })
  }
  // 关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      selectedRowKeys: [],
      skip: 0,
      info: '',
    })
    if (this.state.type !== '客服') {
      this.pageTurning.current.returnFirst();
    }
  }


  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getData())
  }
}

export default WorkerModal;