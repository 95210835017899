import React, { Component } from 'react';
import styles from './index.module.css';
import { Table, Input, Radio, Modal, Button, Popconfirm, message, DatePicker, } from 'antd';
import PageTurning from '../../components/pageTurning';
import Option from '../../components/option';
import InfoModal from './modal';
import { getTemplate, addTemplate, editTemplate } from '../../services/templateManagement';
// import moment from 'moment';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
const dateFormat = 'YYYY-MM-DD';
// moment.locale('zh-cn');

const statusList = ['全部', '启用', '禁用'];
const enableList = ['启用', '禁用'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: '',
      status: '全部',
      skip: 0,
      take: 10,
      currentTemplate: {
        id: 0,
        sort: 0,
        name: '',
        enable: '是',
        remark: '',
        effective_date: '',
        expiry_date: '',
        details: [],
      },

      addModal: false,
      modalType: 'add',
      templateList: [],
    };
    this.templateModal = React.createRef();
  }
  componentDidMount() {
    this.getTemplateList();
    this.pageModal = React.createRef();
  }
  render() {
    const { addModal, info, currentTemplate, modalType, templateList, } = this.state
    const columns = [
      {
        title: '名称',
        align: 'center',
        width: 300,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 100,
        dataIndex: 'enable',
        key: 'enable',
        render: (text, record, index) => {
          return record.enable === '是' ? '启用中' : '禁用';
        }
      },
      {
        title: '生效时间',
        align: 'center',
        width: 100,
        dataIndex: 'effective_date',
        key: 'effective_date',
        render: (text, record, index) => {
          return (<div>{moment(record.effective_date).format('YYYY-MM-DD')}</div>);
        }
      },
      {
        title: '失效时间',
        align: 'center',
        width: 100,
        dataIndex: 'expiry_date',
        key: 'expiry_date',
        render: (text, record, index) => {
          return (<div>{moment(record.expiry_date).format('YYYY-MM-DD')}</div>);
        }
      },
      {
        title: '备注',
        align: 'center',
        width: 200,
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '操作',
        align: 'center',
        width: 150,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showEditModal.bind(this, record)} className={`${styles['btn']} back-color8 color0 margin-r5 cursor-pointer`}>编辑</div>
            </div>
          )
        }
      },
      {
        title: '价目表',
        align: 'center',
        width: 150,
        dataIndex: 'strategy',
        key: 'operstrategyate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showDrawer.bind(this, record)} className={`${styles['btn']} back-color8 color0 margin-r5 cursor-pointer`}>编辑</div>
            </div>
          )
        }
      },
    ];
    return (
      <div className='col-t w100'>
        <Option title={statusList} top={0} changeMyTop={this.changeSummary.bind(this, 'status')} className='margin-b10 '>

          <div className='col-t vertical-lt padding20 back-color0 w100'>
            <div className='row-l'>
              <div className='fz18'>搜索：</div>
              <Input value={info} onChange={this.changeInfo} className='w200' />
              <Button onClick={this.toSearch} type='primary' className='margin-l20'>搜索</Button>
              <Button onClick={this.showAddModal} type='primary' className='margin-l20'>新增</Button>
            </div>
          </div>
        </Option>

        <Table
          columns={columns}
          dataSource={templateList}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
          className='w100'
        />
        <Modal
          title='价格模板'
          visible={addModal}
          width={500}
          onCancel={this.hideAddModal}
          onOk={this.editTemplate}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt'>
            <div className='row-l'>
              <div className='w80 tc'>模板名称：</div>
              <Input value={currentTemplate.name} onChange={this.changeSummary.bind(this, 'name')} placeholder='请输入模板名称' className={styles['input-service']} />
            </div>
            <div className='row-l margin-t10'>
              <div className='w80 tc'>启用状态：</div>
              <Radio.Group disabled={modalType === 'add'} onChange={this.changeSummary.bind(this, 'enable')} value={currentTemplate.enable === '是' ? '启用' : '禁用'} size='large'>
                {
                  enableList.map((item, index) => {
                    return <Radio value={item} key={index} className='fz16 margin-l10'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l margin-t10'>
              <div className='w80 tc'>生效时间：</div>
              <DatePicker locale={locale} value={currentTemplate.effective_date} onChange={this.changeSummary.bind(this, 'effective_date')} placeholder='生效时间' />
            </div>
            <div className='row-l margin-t10'>
              <div className='w80 tc'>失效时间：</div>
              <DatePicker locale={locale} value={currentTemplate.expiry_date} onChange={this.changeSummary.bind(this, 'expiry_date')} placeholder='失效时间' />
            </div>
            <div className='row-l vertical-lt margin-t10'>
              <div className='w80 tc'>模板备注：</div>
              <Input.TextArea value={currentTemplate.remark} onChange={this.changeSummary.bind(this, 'remark')}
                placeholder='请输入模板名称' className={'w300'} rows={3} />
            </div>
          </div>
        </Modal>
        <div className='back-color0 w100'>
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <InfoModal ref={this.templateModal} />
      </div >
    );
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value,
    });
  }
  toSearch = () => {
    this.pageModal.current.returnFirst();
    this.setState({
      skip: 0,
    }, () => this.getTemplateList());
  }
  getTemplateList = async () => {
    const { status, skip, take, info } = this.state;
    let enable = status === '全部' ? '全部' : status === '启用' ? '是' : '否';
    let res = await getTemplate(enable, skip, take, info)
    if (res.status === 200) {
      this.setState({
        templateList: res.data
      })
    }
  }
  changeSummary = (type, e) => {
    let currentTemplate = { ...this.state.currentTemplate };
    switch (type) {
      case 'status':
        this.setState({
          status: statusList[e],
          skip: 0,
        }, () => {
          this.getTemplateList();
          this.pageModal.current.returnFirst();
        });
        break;
      case 'name':
      case 'remark':
        currentTemplate[type] = e.target.value;
        this.setState({
          currentTemplate
        });
        break;
      case 'enable':
        currentTemplate.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          currentTemplate
        });
        break;
      case 'effective_date':
        currentTemplate.effective_date = e;
        this.setState({
          currentTemplate
        });
        break;
      case 'expiry_date':
        currentTemplate.expiry_date = e;
        this.setState({
          currentTemplate
        });
        break;
    }
  }
  editTemplate = async () => {
    const { modalType } = this.state;
    let template = { ...this.state.currentTemplate };
    if (template.name === '' || template.effective_date === '' || template.expiry_date === '') {
      message.error('内容不完整');
      return
    }
    template.effective_date = moment(template.effective_date).format(dateFormat)
    template.expiry_date = moment(template.expiry_date).format(dateFormat)
    if (modalType === 'add') {
      let res = await addTemplate(template);
      if (res.status === 200) {
        this.hideAddModal()
        this.getTemplateList()
      }
    } else {
      let res = await editTemplate(template.id, template)
      if (res.status === 204) {
        this.hideAddModal()
        this.getTemplateList()
      }
    }
  }


  showDrawer = (e) => {
    this.templateModal.current.showDrawer(e);
  }
  showEditModal = (e) => {
    let currentTemplate = e
    currentTemplate.effective_date = moment(e.effective_date)
    currentTemplate.expiry_date = moment(e.expiry_date)
    this.setState({
      addModal: true,
      modalType: 'edit',
      currentTemplate,
    })
  }
  showAddModal = () => {
    this.setState({
      addModal: true,
      modalType: 'add',
    })
  }
  hideAddModal = () => {
    this.setState({
      addModal: false,
      currentTemplate: {
        id: 0,
        sort: 0,
        name: '',
        enable: '是',
        effective_date: '',
        expiry_date: '',
        details: [],
      },
    })
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getTemplateList())
  }
}
export default Index;