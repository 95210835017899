import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Drawer, Popover, Select, Modal, Button, DatePicker, Checkbox } from 'antd';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { KeyOutlined, } from '@ant-design/icons';
import { getDepartment_Data } from '../../config/storage';
import { getShopSummary, getShopOrder, getShopBill } from '../../services/analysisCenter';
import { getServiceList } from '../../services/serviceManagement';
import ManegerModal from '../../components/managerModal/index.jsx';
import { jsToExcel2, correctExcel } from '../../utils/exportExcel';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const selectProp = ['正常测量', '正常安装', '二次测量', '二次安装', '售后测量', '售后安装'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        servicer: 0,
        servicerName: '全部',
        start: '',
        end: '',
      },
      dataSourceShop: [],

      summary: {
        orderNum: 0,
        recharge: 0,
        pay: 0,
        change: 0,
        refound: 0,
        fix: 0,
        present: 0,
        bonus: 0,
        aftersale: 0,
        aftersaleNum: 0,
        sale_num: 0,
        second_num: 0,
        stop_num: 0,
        sameRate: 0,
      },
      shopInfo: {
        currentShop: 0,
        currentShopName: '',
        start: '',
        end: '',
      },
      dataSourceOrder: [],
      count: {
        '订单汇总': 0,
        '订单测量': 0,
        '订单安装': 0,
        '充值汇总': 0,
        '赠送汇总': 0,
        '消费汇总': 0,
        '变更汇总': 0,
        '退款汇总': 0,
        '理赔汇总': 0,
        '修正汇总': 0,
        '售后汇总': 0,
      },
      quickBtn: 0,
      currentProp: selectProp[0],

      billVisible: false,
      skip: 0,
      take: 10,
      billSource: [],
    };
    this.role = getDepartment_Data().roles[0];
    this.manegerModal = React.createRef();//选择客服
  }
  componentDidMount() {
    let start = moment(new Date()).subtract(31, 'days');
    let end = moment(new Date());
    this.setState({
      searchInfo: {
        servicer: 0,
        servicerName: '全部',
        start,
        end,
      },
      shopInfo: {
        currentShop: 0,
        currentShopName: '',
        start,
        end,
      },
    }, () => this.getServicerShop());
  }
  render() {
    const { dataSourceOrder, searchInfo, dataSourceShop, shopInfo, summary, count,
      quickBtn, currentProp, } = this.state;
    const shopColums = [
      {
        title: '商家名称',
        align: 'center',
        width: 220,
        dataIndex: 'name',
        key: 'name',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div className='row-sb w100'>
              <div className='w140 oneline'>{record.name}</div>
              <div className={`${styles['btn-look']} ${styles[shopInfo.currentShop === record.id ? 'btn-look-select2' : 'btn-look-select1']}`}
                onClick={this.selectShop.bind(this, record)}
              >查看</div>
            </div>
          );
        },
        filterResetToDefaultFilteredValue: true,
        ...this.getColumnSearchProps('name'),
      },

      {
        title: '正常测量',
        align: 'center',
        width: 220,
        dataIndex: 'cre_nor_ms',
        key: 'cre_nor_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_nor_ms - b.cre_nor_ms
        }
      },
      {
        title: '正常安装',
        align: 'center',
        width: 220,
        dataIndex: 'cre_nor_ins',
        key: 'cre_nor_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_nor_ins - b.cre_nor_ins
        }
      },
      {
        title: '二次测量',
        align: 'center',
        width: 220,
        dataIndex: 'cre_sec_ms',
        key: 'cre_sec_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_sec_ms - b.cre_sec_ms
        }
      },
      {
        title: '二次安装',
        align: 'center',
        width: 220,
        dataIndex: 'cre_sec_ins',
        key: 'cre_sec_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_sec_ins - b.cre_sec_ins
        }
      },
      {
        title: '售后测量',
        align: 'center',
        width: 220,
        dataIndex: 'cre_aft_ms',
        key: 'cre_aft_ms',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_aft_ms - b.cre_aft_ms
        }
      },
      {
        title: '售后安装',
        align: 'center',
        width: 220,
        dataIndex: 'cre_aft_ins',
        key: 'cre_aft_ins',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.cre_aft_ins - b.cre_aft_ins
        }
      },

    ];

    let colums = this.getPropColumns(currentProp)
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='margin-r10'>商家客服：</div>
              <div className='w160 bord3 none-select h32 tc'>{searchInfo.servicerName}</div>
              <Button onClick={this.showManager} type='primary'>选择</Button>
              <div className={`${styles['tit']} tr`}>下单时间：</div>
              <DatePicker onChange={this.changeTime.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} clearIcon={false} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeTime.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} clearIcon={false} />
              <Button onClick={this.getServicerShop} type='primary' className='margin-l20 margin-r10'>搜索</Button>
            </div>
            <div className='row'>

              <Button onClick={this.toExcel2} type='primary'>导出excel</Button>
              <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
            </div>
          </div>
        </div>
        <div className='w100 col margin-t10 margin-b10'>
          <div className={`${styles['title']} tl w100 bold fz16`}>统计汇总</div>
          <div className='row-sa w100 back-color0 h90'>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_nor_ms}</div>
              <div>正常测量</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_nor_ins}</div>
              <div>正常安装</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_sec_ms}</div>
              <div>二次测量</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_sec_ins}</div>
              <div>二次安装</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_aft_ms}</div>
              <div>售后测量</div>
            </div>
            <div className={`${styles['singleSum']} col`}>
              <div>{summary.cre_aft_ins}</div>
              <div>售后安装</div>
            </div>
          </div>
        </div>
        <div className='w100 back-color0 margin-b10'>
          <Table
            columns={shopColums}
            dataSource={dataSourceShop}
            size={'small'}
            rowKey={record => record.id}
            bordered
          />
        </div>
        <div className='row-sb vertical-lt w100'>
          <div className='w250'>
            <Select value={currentProp}
              onChange={this.changeProp}
              className='w250 margin-b5'
            >
              {selectProp.map((item, index) => {
                return <Select.Option value={item} key={index}>{item}</Select.Option>;
              })}
            </Select>
            <Table
              columns={colums}
              dataSource={dataSourceOrder}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
          <div className='back-color0 padding20'>
            <div className='row-sb padding-tb10'>
              <div className='row-l'>
                <div className='fz18 bold'>{shopInfo.currentShopName ? shopInfo.currentShopName + '-每日' + currentProp : ''}</div>
                <div className={`${styles['selectDate']} color7`}>快速选择：</div>
                <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
                <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
                <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
                <div onClick={this.quickSelect.bind(this, 0)} className={quickBtn === 0 ? styles['quickly-select'] : styles['quickly']}>重置</div>
              </div>
              <div className='row-r'>
                <div className='color7'>汇总：</div>
                <div className={`${styles['summary']} row`}>
                  <div className='bold'>{count[currentProp]}</div>
                  <div className='color5'>（ {currentProp}）</div>
                </div>
              </div>
            </div>
            <div className='padding-b20'>
              <div className='h340 w1300' id='main'></div>
            </div>
          </div>
        </div>
        <ManegerModal
          ref={this.manegerModal}
          all={true}
          selectManager={this.selectManager}
        />
      </div >

    );
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={'搜索模型'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div className={'row-sb'}>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            搜索
          </Button>
          <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            返回
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <KeyOutlined style={{ color: filtered ? '#1890ff' : undefined }} className='fz20 padding-lr5' />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm()
  };
  create = () => {
    const { dataSourceOrder, currentProp } = this.state;
    let currentValue = '';
    let max = 0;
    switch (currentProp) {
      case '正常测量':
        currentValue = 'cre_nor_ms';
        max = 100;
        break;
      case '正常安装':
        currentValue = 'cre_nor_ins';
        max = 100;
        break;
      case '二次测量':
        currentValue = 'cre_sec_ms';
        max = 100;
        break;
      case '二次安装':
        currentValue = 'cre_sec_ins';
        max = 100;
        break;
      case '售后测量':
        currentValue = 'cre_aft_ms';
        max = 100;
        break;
      case '售后安装':
        currentValue = 'cre_aft_ins';
        max = 100;
        break;
    }
    let option = {
      color: [],
      // color: currentProp = '订单汇总' ? ['#07C160', '#f3a75e', '#1890FF'] : ['#07C160'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: dataSourceOrder.map(item => {
          return moment(item.day).format('MM-DD');
        }),
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        max: max
        // max: count
      },
      series: [],
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 25 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    option.color = ['#07C160'];
    option.series = [
      {
        name: '商户每日' + currentProp,
        type: 'bar',
        data: dataSourceOrder.map(item => {
          return item[currentValue];
        }),
        barWidth: '30%',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#07C160',
                fontsize: 15
              }
            }
          }
        }
      }
    ];
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)

  }
  getServicerShop = async () => {
    const { searchInfo, } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getShopSummary(searchInfo.servicer, start, end);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let dataSourceShop = res.data.list;
    let summary = {
      cre_nor_ms: 0,
      cre_nor_ins: 0,
      cre_sec_ms: 0,
      cre_sec_ins: 0,
      cre_aft_ms: 0,
      cre_aft_ins: 0,
    };
    dataSourceShop.map(item => {
      summary.cre_nor_ms += item.cre_nor_ms;
      summary.cre_nor_ins += item.cre_nor_ins;
      summary.cre_sec_ms += item.cre_sec_ms;
      summary.cre_sec_ins += item.cre_sec_ins;
      summary.cre_aft_ms += item.cre_aft_ms;
      summary.cre_aft_ins += item.cre_aft_ins;
    })
    let shopInfo = { ...this.state.shopInfo };
    if (dataSourceShop.length > 0) {
      shopInfo.currentShop = dataSourceShop[0].id;
      shopInfo.currentShopName = dataSourceShop[0].name;
    }
    this.setState({
      dataSourceShop,
      summary,
      shopInfo,
    }, () => this.getCurrentShop());
  }
  changeTime = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    let shopInfo = { ...this.state.shopInfo };
    switch (type) {
      case 'start':
        searchInfo.start = e;
        shopInfo.start = e;
        this.setState({
          searchInfo,
          shopInfo,
        }); break;
      case 'end':
        searchInfo.end = e;
        shopInfo.end = e;
        this.setState({
          searchInfo,
          shopInfo,
        }); break;
    }
  }
  selectShop = (e) => {
    let shopInfo = { ...this.state.shopInfo };
    shopInfo.currentShop = e.id;
    shopInfo.currentShopName = e.name;

    this.setState({
      shopInfo,
      currentProp: selectProp[0],
    }, () => this.getCurrentShop())
  }
  showManager = () => {
    this.manegerModal.current.onShow(false);
  }
  selectManager = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    searchInfo.servicer = e[0].id;
    searchInfo.servicerName = e[0].name;
    this.setState({
      searchInfo,
    })
  }
  reset = () => {
    let searchInfo = {
      servicer: 0,
      servicerName: '全部',
      start: moment(new Date()).subtract(31, 'days'),
      end: moment(new Date()),
    }
    this.setState({
      searchInfo,
    }, () => this.getServicerShop())
  }
  quickSelect = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    let shopInfo = { ...this.state.shopInfo };
    if (e === 0) {
      shopInfo.start = searchInfo.start;
      shopInfo.end = searchInfo.end;
    } else {
      let start = moment(new Date()).subtract(e, 'days');
      let end = moment(new Date());
      shopInfo.start = start;
      shopInfo.end = end;
    }
    this.setState({
      shopInfo,
      quickBtn: e,
    }, () => this.getCurrentShop())
  }
  changeProp = (e) => {
    this.setState({
      currentProp: e,
    }, () => this.create())
  }
  getCurrentShop = async () => {
    const { shopInfo } = this.state;
    let start = moment(shopInfo.start).format(dateFormat)
    let end = moment(shopInfo.end).format(dateFormat)
    let res = await getShopOrder(shopInfo.currentShop, start, end);
    if (res.status !== 200) {
      message.error(res, data);
      return;
    }
    let count = {
      '正常测量': 0,
      '正常安装': 0,
      '二次测量': 0,
      '二次安装': 0,
      '售后测量': 0,
      '售后安装': 0,
    };
    res.data.list.map(item => {
      count['正常测量'] += item.cre_nor_ms;
      count['正常安装'] += item.cre_nor_ins;
      count['二次测量'] += item.cre_sec_ms;
      count['二次安装'] += item.cre_sec_ins;
      count['售后测量'] += item.cre_aft_ms;
      count['售后安装'] += item.cre_aft_ins;
    })
    this.setState({
      dataSourceOrder: res.data.list,
      count,
    }, () => this.create())
  }
  getPropColumns = (e) => {
    if (e === '正常测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 100,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '正常测量（单）',
          align: 'center',
          width: 150,
          dataIndex: 'cre_nor_ms',
          key: 'cre_nor_ms',
        },
      ];
    } else if (e === '正常安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '正常安装（单）',
          align: 'center',
          width: 150,
          dataIndex: 'cre_nor_ins',
          key: 'cre_nor_ins',
          className: 'padding5',
        },
      ];
    } else if (e === '二次测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '二次测量（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'cre_sec_ms',
          key: 'cre_sec_ms',
          className: 'padding5',
        },
      ];
    } else if (e === '二次安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '二次安装（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'cre_sec_ins',
          key: 'cre_sec_ins',
          className: 'padding5',
        },
      ];
    } else if (e === '售后测量') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后测量（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'cre_aft_ms',
          key: 'cre_aft_ms',
          className: 'padding5',
        },
      ];
    } else if (e === '售后安装') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后安装（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'cre_aft_ins',
          key: 'cre_aft_ins',
          className: 'padding5',
        },
      ];
    }
  }

  toExcel2 = () => {
    const { dataSourceShop, } = this.state;
    let colums = [
      {
        title: '商家名称',
        align: 'center',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '正常测量',
        align: 'center',
        dataIndex: 'cre_nor_ms',
        key: 'cre_nor_ms',
      },
      {
        title: '正常安装',
        align: 'center',
        dataIndex: 'cre_nor_ins',
        key: 'cre_nor_ins',
      },
      {
        title: '二次测量',
        align: 'center',
        dataIndex: 'cre_sec_ms',
        key: 'cre_sec_ms',
      },
      {
        title: '二次安装',
        align: 'center',
        dataIndex: 'cre_sec_ins',
        key: 'cre_sec_ins',
      },
      {
        title: '售后测量',
        align: 'center',
        dataIndex: 'cre_aft_ms',
        key: 'cre_aft_ms',
      },
      {
        title: '售后安装',
        align: 'center',
        dataIndex: 'cre_aft_ins',
        key: 'cre_aft_ins',
      },
    ];
    let data = dataSourceShop.map(item => {
      return {
        name: item.name,
        cre_nor_ms: item.cre_nor_ms,
        cre_nor_ins: item.cre_nor_ins,
        cre_sec_ms: item.cre_sec_ms,
        cre_sec_ins: item.cre_sec_ins,
        cre_aft_ms: item.cre_aft_ms,
        cre_aft_ins: item.cre_aft_ins,
      };
    })
    correctExcel(colums, data, '商户订单分析');
  }
}

export default Index;