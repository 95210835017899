import React, { Component } from 'react';
import { Modal, Input, Table, message, Button } from 'antd';
import { getManager } from '../../services/orderCore';
import _ from 'lodash';

class ManegerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      info: '',
      type: '全部',
      dataSource: [],
      data: [],
      confirmLoading: false,
      selectedRowKeys: [],
      multiple: false,
      prop: '',
    }
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
    ]
  }
  render() {
    const { visible, info, type, data, confirmLoading, selectedRowKeys } = this.state;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'checkbox',
      onChange: this.onSelectChange,
    };
    return (
      <Modal
        title='选择客服'
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <div className='row-l margin-b10'>
          <Input value={info} onChange={this.changeInfo} className='w400' />
          <Button onClick={this.getList} type='primary'>搜索</Button>
        </div>
        <div className='row-l margin-b10'>
          {
            ['全部', '前台', '后台', '数据', '其他'].map((item, index) => {
              return (
                <div onClick={this.changeType.bind(this, item)} className={`bar-item ${type === item ? 'bar-item-color' : ''}`} key={index}>
                  {item}
                </div>
              );
            })

          }
        </div>
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={data}
          size={'small'}
          rowKey={record => record.id}
          bordered
        />
      </Modal>
    );
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value,
    });
  }
  changeType = (e) => {
    const { dataSource, } = this.state;
    let newData = this.onSelect(dataSource, e);
    this.setState({
      type: e,
      data: newData,
    });
  }
  getList = async () => {
    const { info, type } = this.state;
    let res = await getManager({ name: info, skip: 0, take: 200 })
    let data = this.onSelect(res.data, type);

    this.setState({
      data,
      dataSource: res.data,
    })
  }
  onSelect = (data, type) => {
    if (type === '前台') {
      data = data.filter(r => r.name.includes('前台'));
    } else if (type === '后台') {
      data = data.filter(r => r.name.includes('后台'));
    } else if (type === '数据') {
      data = data.filter(r => r.name.includes('数据'));
    } else if (type === '其他') {
      data = data.filter(r => !r.name.includes('前台') && !r.name.includes('后台') && !r.name.includes('数据'));
    } else if (type === '全部' && this.props.all) {
      let exit = data.findIndex(r => r.name === '全部');
      if (exit < 0) {
        data.unshift({
          id: 0,
          name: '全部'
        })
      }
    }
    return data;
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    if (this.state.multiple) {
      this.setState({
        selectedRowKeys,
      });
    } else {
      const diff = _.difference(selectedRowKeys, this.state.selectedRowKeys);
      if (diff.length > 0) {
        this.setState({
          selectedRowKeys: diff
        });
      } else {
        this.setState({
          selectedRowKeys: selectedRowKeys
        });
      }
    }
  }
  // 完成
  onOk = () => {
    let { data, selectedRowKeys, prop } = this.state;
    if (selectedRowKeys.length === 0) {
      message.warn('未选择客服');
      return;
    }
    this.setState({
      confirmLoading: true,
    })
    let selectedRows = data.filter(r => selectedRowKeys.includes(r.id));
    this.props.selectManager(selectedRows, prop);
    this.onCancel();
    this.setState({
      confirmLoading: false,
    })
  }
  onShow = (e, prop) => {
    this.setState({
      visible: true,
      multiple: e,
      prop: prop,
    })
    this.getList();
  }
  onCancel = () => {
    this.setState({
      visible: false,
      info: '',
      type: '全部',
      multiple: false,
      selectedRowKeys: [],
      data: [],
      dataSource: [],
      prop: '',
    })
  }
}
export default ManegerModal;