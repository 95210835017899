import React, { Component } from 'react';
import { message, Table, Button, DatePicker, Drawer } from 'antd';
import { getSingleOrder2, getSingleFlow } from '../../services/analysisCenter';
import { getDepartment_Data } from '../../config/storage';
import { correctExcel } from '../../utils/exportExcel';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const dateFormat2 = 'YYYY/MM/DD-HH:mm:ss';
const today = moment(new Date());
class SingleOrdeer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: today,
      dataSource: [],
      downLoad: false,
      summary: {
        price_app: 0,
        coupon: 0,
        change: 0,
        fix: 0,
        bonus: 0,
        aftersale: 0,
        refound: 0,
        shop_price: 0,
        worker_app: 0,
        worker_fix: 0,
        worker_bonus: 0,
        worker_rp: 0,
        worker_aftersale: 0,
        worker_price: 0,
      },
    };
    this.role = getDepartment_Data().roles[0];
    this.columns = [
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        key: 'orderInfo',
        dataIndex: 'orderInfo',
        className: 'padding2',
      },
      {
        title: '服务类型',
        align: 'center',
        width: 120,
        dataIndex: 'service',
        key: 'service',
        className: 'padding2',
      },
      {
        title: '客户名称',
        align: 'center',
        width: 120,
        dataIndex: 'name',
        key: 'name',
        className: 'padding2 mw110',
      },
      {
        title: '联系方式',
        align: 'center',
        width: 100,
        dataIndex: 'phone',
        key: 'phone',
        className: 'padding2',

      },
      {
        title: '下单商户',
        align: 'center',
        width: 120,
        dataIndex: 'shop',
        key: 'shop',
        className: 'padding2 miw80',

      },
      {
        title: '结算时间',
        align: 'center',
        width: 100,
        dataIndex: 'check_time',
        key: 'check_time',
        className: 'padding2 miw80',
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 100,
        dataIndex: 'worker',
        key: 'worker',
        className: 'padding2',
      },
      {
        title: '商户费用',
        align: 'center',
        width: 100,
        dataIndex: 'price_app',
        key: 'price_app',
        className: 'padding2',
      },
      {
        title: '优惠券',
        align: 'center',
        width: 100,
        dataIndex: 'coupon',
        key: 'coupon',
        className: 'padding2',
      },
      {
        title: '商家变更',
        align: 'center',
        width: 100,
        dataIndex: 'change',
        key: 'change',
        className: 'padding2',
      },
      {
        title: '商家修正',
        align: 'center',
        width: 100,
        dataIndex: 'fix',
        key: 'fix',
        className: 'padding2',
      },
      {
        title: '商家好评',
        align: 'center',
        width: 100,
        dataIndex: 'bonus',
        key: 'bonus',
        className: 'padding2',
      },
      {
        title: '商家理赔',
        align: 'center',
        width: 100,
        dataIndex: 'aftersale',
        key: 'aftersale',
        className: 'padding2',
      },
      {
        title: '商家退款',
        align: 'center',
        width: 100,
        dataIndex: 'refound',
        key: 'refound',
        className: 'padding2',
      },
      {
        title: '商家费用汇总',
        align: 'center',
        width: 100,
        dataIndex: 'shop_price',
        key: 'shop_price',
        className: 'padding2',
      },
      {
        title: '师傅费用',
        align: 'center',
        width: 100,
        dataIndex: 'worker_app',
        key: 'worker_app',
        className: 'padding2',
      },
      {
        title: '师傅变更',
        align: 'center',
        width: 100,
        dataIndex: 'worker_fix',
        key: 'worker_fix',
        className: 'padding2',
      },
      {
        title: '师傅好评',
        align: 'center',
        width: 100,
        dataIndex: 'worker_bonus',
        key: 'worker_bonus',
        className: 'padding2',
      },
      {
        title: '师傅奖惩',
        align: 'center',
        width: 100,
        dataIndex: 'worker_rp',
        key: 'worker_rp',
        className: 'padding2',
      },
      {
        title: '师傅理赔',
        align: 'center',
        width: 100,
        dataIndex: 'worker_aftersale',
        key: 'worker_aftersale',
        className: 'padding2',
      },
      {
        title: '师傅费用汇总',
        align: 'center',
        width: 100,
        dataIndex: 'worker_price',
        key: 'worker_price',
        className: 'padding2',
      },

    ];
  }
  componentDidMount() {
    this.onSearch()
  }
  render() {
    const { start, dataSource, downLoad, summary } = this.state;
    return (
      <div className='col-t w100'>
        <div className='row-sb padding20 back-color0 w100'>
          <div className='row-l'>
            <div>结算时间：</div>
            <DatePicker onChange={this.startTime} value={start} locale={locale} format={dateFormat} allowClear={false} />
            <Button onClick={this.onSearch} loading={downLoad} type='primary' className='margin-l20 margin-r10'>搜索</Button>
          </div>
          <div className='row'>
            {
              [9, 11].includes(this.role) &&
              <div>
                <Button onClick={this.toExcel} type='primary' className='margin-r20'>导出</Button>
              </div>
            }
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <Table
            columns={this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
            className='w100'
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={7}>汇总</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.price_app}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.coupon}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.change}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.fix}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.bonus}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.aftersale}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.refound}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.shop_price}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_app}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_fix}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_bonus}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_rp}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_aftersale}</Table.Summary.Cell>
                    <Table.Summary.Cell>{summary.worker_price}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />
        </div>
      </div>
    );
  }
  startTime = (e) => {
    this.setState({
      start: e,
    })
  }
  onSearch = async () => {
    const { start } = this.state;
    this.setState({
      downLoad: true,
    });
    let start2 = moment(start).format(dateFormat)
    let res = await getSingleOrder2(start2);
    if (res.status === 200) {
      let summary = {
        price_app: 0,
        coupon: 0,
        change: 0,
        fix: 0,
        bonus: 0,
        aftersale: 0,
        refound: 0,
        shop_price: 0,
        worker_app: 0,
        worker_fix: 0,
        worker_bonus: 0,
        worker_rp: 0,
        worker_aftersale: 0,
        worker_price: 0,
      };
      res.data.map(item => {
        summary.price_app = Number(Number(summary.price_app + item.price_app).toFixed(1));
        summary.coupon = Number(Number(summary.coupon + item.coupon).toFixed(1));
        summary.change = Number(Number(summary.change + item.change).toFixed(1));
        summary.fix = Number(Number(summary.fix + item.fix).toFixed(1));
        summary.bonus = Number(Number(summary.bonus + item.bonus).toFixed(1));
        summary.aftersale = Number(Number(summary.aftersale + item.aftersale).toFixed(1));
        summary.refound = Number(Number(summary.refound + item.refound).toFixed(1));
        summary.shop_price = Number(Number(summary.shop_price + item.shop_price).toFixed(1));
        summary.worker_app = Number(Number(summary.worker_app + item.worker_app).toFixed(1));
        summary.worker_fix = Number(Number(summary.worker_fix + item.worker_fix).toFixed(1));
        summary.worker_bonus = Number(Number(summary.worker_bonus + item.worker_bonus).toFixed(1));
        summary.worker_rp = Number(Number(summary.worker_rp + item.worker_rp).toFixed(1));
        summary.worker_aftersale = Number(Number(summary.worker_aftersale + item.worker_aftersale).toFixed(1));
        summary.worker_price = Number(Number(summary.worker_price + item.worker_price).toFixed(1));
        item.check_time = moment(item.check_time).format('YYYY-MM-DD')
      })
      this.setState({
        downLoad: false,
        dataSource: res.data,
        summary,
      })
    } else {
      this.setState({
        downLoad: false,
      })
    }
  }
  toExcel = () => {
    const { dataSource, start } = this.state;
    let start2 = moment(start).format('YYYY-MM-DD');
    correctExcel(this.columns, dataSource, `${start2}结算分析`);
  }
}
export default SingleOrdeer;